import styled from "styled-components";

export const FullHeightContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 0 solid white; /* Need this hack for CSS, hey ho */
`;

export const MainContainer = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  margin: 0;
`;

export const PaddingContainer = styled.div`
  margin: 1.55rem 1.55rem 7rem 1.55rem;
`;

export const FooterContainer = styled.div`
  flex-shrink: 0;
`;

export const TermsParagraph = styled.div`
  margin: 0.2rem 0 1rem 0;

  & ul {
    padding: 0.5rem 2rem;
  }
`;

export const TermsHead1 = styled.h1`
  font-weight: 700;
  font-size: 2.3rem;
  line-height: 2.3rem;
  margin: 3.5rem 0 2rem 0;
`;

export const TermsHead2 = styled.h3`
  font-weight: 100;
  margin: 2.2rem 0 1rem 0;
`;
