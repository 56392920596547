import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import cookies from "js-cookie";
import { Key } from "@helpers/Constants";
import globalAxios from "axios";
import { Company, CompanyRoleEnum, UserRoleEnum } from "@api";
import asyncPromise from "@helpers/utils/asyncPromise";
import { UserRole, CompanyRole } from "@helpers/types";
import useApi from "@helpers/hooks/useApi";
import { CircularProgress } from "@mui/material";

// NOTE for some reason the generated Swagger package misses some of
// the returned values
type UserRoleResponseUser = {
  first_name: string;
  last_name: string;
  company?: Company;
  role?: UserRoleEnum;
};

export type BasicUser = {
  id?: number;
  firstName?: string;
  lastName?: string;
  company?: Company;
  companyRole?: CompanyRole;
  userRole?: UserRole;
};

// Context

export interface IAuthContext {
  isLoading: boolean;
  user?: BasicUser;
  refresh: () => void;
  setAccessToken: (token: string) => void;
  clearAccessToken: () => void;
}

const defaultValue: IAuthContext = {
  isLoading: false,
  user: undefined,
  refresh: () => {},
  setAccessToken: () => {},
  clearAccessToken: () => {},
};

export const AuthContext = createContext(defaultValue);

// Provider

interface Props {
  children: React.ReactNode;
  initialUser?: BasicUser;
}

export const AuthProvider: React.FC<Props> = ({ children, initialUser }) => {
  const [storedAccessToken, setStoredAccessToken] = useState<
    string | undefined
  >();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<BasicUser | undefined>(initialUser);
  const [lastRefreshRequested, setLastRefreshRequested] = useState("");
  const api = useApi();

  const updateUserFromAPI = async () => {
    try {
      setIsLoading(true);
      const response = await asyncPromise(api.apiV1UserRead());
      const { data } = response?.[0] ?? {};
      const {
        first_name: firstName,
        last_name: lastName,
        company,
        role,
      } = data as unknown as UserRoleResponseUser;

      let companyRole = CompanyRole.PUBLIC;
      if (company?.role === CompanyRoleEnum.A) {
        companyRole = CompanyRole.ADMIN;
      } else if (company?.role === CompanyRoleEnum.T) {
        companyRole = CompanyRole.TESTER;
      } else {
        companyRole = CompanyRole.CLIENT;
      }

      const userRole =
        role === UserRoleEnum.A ? UserRole.ADMIN : UserRole.MEMBER;

      setUser({
        id: data.id,
        firstName,
        lastName,
        company,
        companyRole,
        userRole,
      });
      setIsLoading(false);
    } catch (err) {
      // Can be a network error, in which case we positively don't want to log people out.
      console.log(err);
    }
  };

  const updateAccessToken = async (token: string | undefined) => {
    globalAxios.interceptors.request.use((config) => {
      const restoredToken = cookies.get(Key.COOKIE_ACCESS_TOKEN);
      if (config && config.headers) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = restoredToken
          ? `Token ${restoredToken}`
          : "";
        // eslint-disable-next-line no-param-reassign
        config.headers["Cache-Control"] = "no-cache";
      }
      return config;
    });

    globalAxios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          return Promise.reject(error.response);
        }
        if (error.message) {
          return Promise.reject(error.message);
        }
        return Promise.reject(error);
      },
    );

    if (token) {
      await updateUserFromAPI();
    }

    setStoredAccessToken(token);
    setIsLoading(false);
  };

  useEffect(() => {
    const restoredToken = cookies.get(Key.COOKIE_ACCESS_TOKEN);
    setStoredAccessToken(restoredToken);
    if (!restoredToken) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (storedAccessToken) {
      updateAccessToken(storedAccessToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastRefreshRequested, storedAccessToken]);

  const setAccessToken = useCallback((token: string) => {
    cookies.set(Key.COOKIE_ACCESS_TOKEN, token, { expires: 14 });
    setStoredAccessToken(token);
  }, []);

  const clearAccessToken = useCallback(() => {
    cookies.remove(Key.COOKIE_ACCESS_TOKEN);
    setStoredAccessToken(undefined);
    setUser(undefined);
    setIsLoading(false);
  }, []);

  const refresh = useCallback(() => {
    setLastRefreshRequested(`${new Date()}`);
  }, []);

  const value = useMemo(
    () => ({
      isLoading,
      clearAccessToken,
      refresh,
      setAccessToken,
      user,
    }),
    [isLoading, clearAccessToken, refresh, setAccessToken, user],
  );

  if (isLoading) {
    return <CircularProgress size={10} />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
