export const COMPANY_PLACEHOLDER_TEXT = "_placeholder_";

export enum UserRole {
  "ADMIN" = "ADMIN",
  "MEMBER" = "MEMBER",
}

export enum CompanyRole {
  "ADMIN" = "ADMIN",
  "TESTER" = "TESTER",
  "CLIENT" = "CLIENT",
  "PUBLIC" = "PUBLIC",
}

export interface IMenuItem {
  title: string;
  path: string;
  isCompleted: boolean;
  isActive: boolean;
}

export type ReactQueryConfig = { enabled?: boolean };
