import React, { createContext, useMemo } from "react";
import { ApiApi } from "@api";

export interface IApiContext {
  api?: ApiApi;
}

const defaultValue: IApiContext = {};

export const ApiContext = createContext(defaultValue);

interface Props {
  children: React.ReactNode;
  api: ApiApi;
}

export const ApiProvider: React.FC<Props> = ({ children, api }) => {
  const value = useMemo(() => ({ api }), [api]);

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};
