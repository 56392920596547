/* tslint:disable */
/* eslint-disable */
/**
 * TestTheTest API
 * TestTheTest
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiKey
 */
export interface ApiKey {
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    project_id?: string;
}
/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    file?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface AutoRun
 */
export interface AutoRun {
    /**
     * 
     * @type {string}
     * @memberof AutoRun
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoRun
     */
    summary?: string;
    /**
     * 
     * @type {AutoRunStatusEnum}
     * @memberof AutoRun
     */
    status?: AutoRunStatusEnum;
    /**
     * 
     * @type {object}
     * @memberof AutoRun
     */
    stats?: object;
    /**
     * 
     * @type {string}
     * @memberof AutoRun
     */
    user_agent?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoRun
     */
    created?: string;
    /**
     * 
     * @type {object}
     * @memberof AutoRun
     */
    jobs?: object;
    /**
     * 
     * @type {Bundle}
     * @memberof AutoRun
     */
    bundle?: Bundle;
    /**
     * 
     * @type {string}
     * @memberof AutoRun
     */
    env_variables?: string;
    /**
     * 
     * @type {Array<FlowSnapshotRead>}
     * @memberof AutoRun
     */
    snapshots?: Array<FlowSnapshotRead>;
    /**
     * 
     * @type {Array<AutoRunAttachments>}
     * @memberof AutoRun
     */
    attachments?: Array<AutoRunAttachments>;
    /**
     * 
     * @type {string}
     * @memberof AutoRun
     */
    finalized_at?: string | null;
}
/**
 * 
 * @export
 * @interface AutoRunAttachments
 */
export interface AutoRunAttachments {
    /**
     * 
     * @type {string}
     * @memberof AutoRunAttachments
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AutoRunAttachments
     */
    file?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoRunAttachments
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoRunAttachments
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface AutoRunJob
 */
export interface AutoRunJob {
    /**
     * 
     * @type {string}
     * @memberof AutoRunJob
     */
    id?: string;
    /**
     * 
     * @type {AutoRunJobStatusEnum}
     * @memberof AutoRunJob
     */
    status?: AutoRunJobStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AutoRunJob
     */
    leased_until?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoRunJob
     */
    errors?: string;
    /**
     * 
     * @type {number}
     * @memberof AutoRunJob
     */
    retries?: number;
    /**
     * 
     * @type {string}
     * @memberof AutoRunJob
     */
    logs?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoRunJob
     */
    container?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoRunJob
     */
    command?: string;
}
/**
 * 
 * @export
 * @interface AutoRunJobRead
 */
export interface AutoRunJobRead {
    /**
     * 
     * @type {string}
     * @memberof AutoRunJobRead
     */
    id?: string;
    /**
     * 
     * @type {AutoRunJobStatusEnum}
     * @memberof AutoRunJobRead
     */
    status?: AutoRunJobStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AutoRunJobRead
     */
    leased_until?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoRunJobRead
     */
    errors?: string;
    /**
     * 
     * @type {number}
     * @memberof AutoRunJobRead
     */
    retries?: number;
    /**
     * 
     * @type {string}
     * @memberof AutoRunJobRead
     */
    logs?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoRunJobRead
     */
    container?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoRunJobRead
     */
    command?: string;
    /**
     * 
     * @type {Autorun}
     * @memberof AutoRunJobRead
     */
    autorun?: Autorun;
    /**
     * 
     * @type {Bundle}
     * @memberof AutoRunJobRead
     */
    bundle?: Bundle;
}
/**
 * 
 * @export
 * @interface AutoRunJobReadAllOf
 */
export interface AutoRunJobReadAllOf {
    /**
     * 
     * @type {Autorun}
     * @memberof AutoRunJobReadAllOf
     */
    autorun?: Autorun;
    /**
     * 
     * @type {Bundle}
     * @memberof AutoRunJobReadAllOf
     */
    bundle?: Bundle;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AutoRunJobStatusEnum {
    W = 'W',
    P = 'P',
    C = 'C'
}

/**
 * 
 * @export
 * @interface AutoRunJobWrite
 */
export interface AutoRunJobWrite {
    /**
     * 
     * @type {string}
     * @memberof AutoRunJobWrite
     */
    id?: string;
    /**
     * 
     * @type {AutoRunJobStatusEnum}
     * @memberof AutoRunJobWrite
     */
    status?: AutoRunJobStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AutoRunJobWrite
     */
    leased_until?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutoRunJobWrite
     */
    errors?: string;
    /**
     * 
     * @type {number}
     * @memberof AutoRunJobWrite
     */
    retries?: number;
    /**
     * 
     * @type {string}
     * @memberof AutoRunJobWrite
     */
    logs?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoRunJobWrite
     */
    container?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoRunJobWrite
     */
    command?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoRunJobWrite
     */
    autorun?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoRunJobWrite
     */
    bundle?: string;
}
/**
 * 
 * @export
 * @interface AutoRunJobWriteAllOf
 */
export interface AutoRunJobWriteAllOf {
    /**
     * 
     * @type {string}
     * @memberof AutoRunJobWriteAllOf
     */
    autorun?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoRunJobWriteAllOf
     */
    bundle?: string;
}
/**
 * 
 * @export
 * @interface AutoRunList
 */
export interface AutoRunList {
    /**
     * 
     * @type {string}
     * @memberof AutoRunList
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoRunList
     */
    summary?: string;
    /**
     * 
     * @type {AutoRunStatusEnum}
     * @memberof AutoRunList
     */
    status?: AutoRunStatusEnum;
    /**
     * 
     * @type {object}
     * @memberof AutoRunList
     */
    stats?: object;
    /**
     * 
     * @type {string}
     * @memberof AutoRunList
     */
    user_agent?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoRunList
     */
    created?: string;
    /**
     * 
     * @type {object}
     * @memberof AutoRunList
     */
    jobs?: object;
}
/**
 * 
 * @export
 * @interface AutoRunSchedule
 */
export interface AutoRunSchedule {
    /**
     * 
     * @type {number}
     * @memberof AutoRunSchedule
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AutoRunSchedule
     */
    autorun: string;
    /**
     *  Accepting a string formatted: * * * * * command to be executed min(0–59) hour(0–23) day of month(1–31) month(1–12) day of week(0–6)(Sunday=0)  valid inputs: `*_/20 * * * *` - Every 20 minutes `0 0 * * *` - Every day at midnight `0 6 * * 1-5` - Every weekday at 6 am 
     * @type {string}
     * @memberof AutoRunSchedule
     */
    crontab: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AutoRunStatusEnum {
    S = 'S',
    F = 'F'
}

/**
 * 
 * @export
 * @interface AutoRunView
 */
export interface AutoRunView {
    /**
     * 
     * @type {string}
     * @memberof AutoRunView
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoRunView
     */
    summary?: string;
    /**
     * 
     * @type {AutoRunStatusEnum}
     * @memberof AutoRunView
     */
    status?: AutoRunStatusEnum;
    /**
     * 
     * @type {object}
     * @memberof AutoRunView
     */
    stats?: object;
    /**
     * 
     * @type {string}
     * @memberof AutoRunView
     */
    user_agent?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoRunView
     */
    created?: string;
    /**
     * 
     * @type {object}
     * @memberof AutoRunView
     */
    jobs?: object;
    /**
     * 
     * @type {Bundle}
     * @memberof AutoRunView
     */
    bundle?: Bundle;
    /**
     * 
     * @type {string}
     * @memberof AutoRunView
     */
    env_variables?: string;
    /**
     * 
     * @type {Array<FlowSnapshotRead>}
     * @memberof AutoRunView
     */
    snapshots?: Array<FlowSnapshotRead>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AutoRunView
     */
    attachments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AutoRunView
     */
    finalized_at?: string | null;
    /**
     * 
     * @type {object}
     * @memberof AutoRunView
     */
    project?: object;
}
/**
 * 
 * @export
 * @interface Autorun
 */
export interface Autorun {
    /**
     * 
     * @type {string}
     * @memberof Autorun
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Autorun
     */
    file?: string;
    /**
     * 
     * @type {string}
     * @memberof Autorun
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Autorun
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface Avatar
 */
export interface Avatar {
    /**
     * 
     * @type {string}
     * @memberof Avatar
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Avatar
     */
    file?: string;
    /**
     * 
     * @type {string}
     * @memberof Avatar
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Avatar
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface Bundle
 */
export interface Bundle {
    /**
     * 
     * @type {string}
     * @memberof Bundle
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Bundle
     */
    file?: string;
    /**
     * 
     * @type {string}
     * @memberof Bundle
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Bundle
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface Case
 */
export interface Case {
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    test_data?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    expected_result?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    flow?: string;
    /**
     * 
     * @type {string}
     * @memberof Case
     */
    cypress_commands?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Case
     */
    is_suppressed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Case
     */
    is_approved?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Case
     */
    attachments?: Array<string>;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Case
     */
    attachments_full?: Array<Attachment>;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    order?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Case
     */
    tags?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Case
     */
    perma_id?: number;
}
/**
 * 
 * @export
 * @interface CaseStats
 */
export interface CaseStats {
    /**
     * 
     * @type {string}
     * @memberof CaseStats
     */
    modified?: string;
    /**
     * 
     * @type {CheckStatusEnum}
     * @memberof CaseStats
     */
    status?: CheckStatusEnum;
}
/**
 * 
 * @export
 * @interface Check
 */
export interface Check {
    /**
     * 
     * @type {string}
     * @memberof Check
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Check
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Check
     */
    expected_result?: string;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Check
     */
    attachments?: Array<Attachment>;
    /**
     * 
     * @type {string}
     * @memberof Check
     */
    test_data?: string;
    /**
     * 
     * @type {string}
     * @memberof Check
     */
    user?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Check
     */
    comment?: string;
    /**
     * 
     * @type {CheckStatusEnum}
     * @memberof Check
     */
    status: CheckStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Check
     */
    state?: CheckStateEnum;
    /**
     * 
     * @type {string}
     * @memberof Check
     */
    cypress_commands?: string;
    /**
     * 
     * @type {string}
     * @memberof Check
     */
    case_id?: string;
    /**
     * 
     * @type {number}
     * @memberof Check
     */
    order?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Check
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Check
     */
    notes?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Check
     */
    result_attachments?: Array<string>;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Check
     */
    result_attachments_full?: Array<Attachment>;
    /**
     * 
     * @type {number}
     * @memberof Check
     */
    perma_id?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Check
     */
    named_bugs?: Array<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum CheckStateEnum {
    U = 'U',
    Empty = ''
}

/**
 * 
 * @export
 * @interface CheckRetry
 */
export interface CheckRetry {
    /**
     * 
     * @type {CheckStatusEnum}
     * @memberof CheckRetry
     */
    status: CheckStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CheckRetry
     */
    error: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CheckStatusEnum {
    P = 'P',
    S = 'S',
    F = 'F',
    H = 'H'
}

/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    address_1: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    address_2?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    post_code: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    phone_number?: string;
    /**
     * 
     * @type {Avatar}
     * @memberof Company
     */
    avatar?: Avatar | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    onboarding?: CompanyOnboardingEnum;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    promo_code?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    role?: CompanyRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    contract_start_date?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum CompanyOnboardingEnum {
    N = 'N',
    A = 'A',
    P = 'P'
}
/**
    * @export
    * @enum {string}
    */
export enum CompanyRoleEnum {
    C = 'C',
    T = 'T',
    A = 'A'
}

/**
 * 
 * @export
 * @interface CreateAttachmentFromURL
 */
export interface CreateAttachmentFromURL {
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentFromURL
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentFromURL
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentFromURL
     */
    file?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentFromURL
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAttachmentFromURL
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateAttachmentFromURL
     */
    width?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateAttachmentFromURL
     */
    file_size?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentFromURL
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAttachmentFromURL
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface DashboardRun
 */
export interface DashboardRun {
    /**
     * 
     * @type {string}
     * @memberof DashboardRun
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardRun
     */
    is_autorun: boolean;
    /**
     * 
     * @type {string}
     * @memberof DashboardRun
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardRun
     */
    summary: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardRun
     */
    user_agent: string;
    /**
     * 
     * @type {object}
     * @memberof DashboardRun
     */
    project?: object;
    /**
     * 
     * @type {object}
     * @memberof DashboardRun
     */
    stats?: object;
}
/**
 * 
 * @export
 * @interface ExternalAutoRun
 */
export interface ExternalAutoRun {
    /**
     * 
     * @type {string}
     * @memberof ExternalAutoRun
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalAutoRun
     */
    summary?: string;
    /**
     * 
     * @type {AutoRunStatusEnum}
     * @memberof ExternalAutoRun
     */
    status?: AutoRunStatusEnum;
    /**
     * 
     * @type {object}
     * @memberof ExternalAutoRun
     */
    stats?: object;
    /**
     * 
     * @type {string}
     * @memberof ExternalAutoRun
     */
    user_agent?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalAutoRun
     */
    created?: string;
    /**
     * 
     * @type {number}
     * @memberof ExternalAutoRun
     */
    project: number;
    /**
     * 
     * @type {Bundle}
     * @memberof ExternalAutoRun
     */
    bundle?: Bundle;
}
/**
 * 
 * @export
 * @interface Flow
 */
export interface Flow {
    /**
     * 
     * @type {string}
     * @memberof Flow
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Flow
     */
    description: string;
    /**
     * 
     * @type {Array<Case>}
     * @memberof Flow
     */
    cases?: Array<Case>;
    /**
     * 
     * @type {number}
     * @memberof Flow
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof Flow
     */
    cypress_commands?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Flow
     */
    is_approved?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Flow
     */
    status?: FlowStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FlowStatusEnum {
    V = 'V',
    H = 'H',
    D = 'D'
}

/**
 * 
 * @export
 * @interface FlowSnapshot
 */
export interface FlowSnapshot {
    /**
     * 
     * @type {string}
     * @memberof FlowSnapshot
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowSnapshot
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof FlowSnapshot
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof FlowSnapshot
     */
    cypress_commands?: string;
}
/**
 * 
 * @export
 * @interface FlowSnapshotRead
 */
export interface FlowSnapshotRead {
    /**
     * 
     * @type {string}
     * @memberof FlowSnapshotRead
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowSnapshotRead
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof FlowSnapshotRead
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof FlowSnapshotRead
     */
    cypress_commands?: string;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof FlowSnapshotRead
     */
    attachments?: Array<Attachment>;
    /**
     * 
     * @type {Array<Check>}
     * @memberof FlowSnapshotRead
     */
    checks?: Array<Check>;
}
/**
 * 
 * @export
 * @interface FlowSnapshotReadAllOf
 */
export interface FlowSnapshotReadAllOf {
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof FlowSnapshotReadAllOf
     */
    attachments?: Array<Attachment>;
    /**
     * 
     * @type {Array<Check>}
     * @memberof FlowSnapshotReadAllOf
     */
    checks?: Array<Check>;
}
/**
 * 
 * @export
 * @interface FlowSnapshotWrite
 */
export interface FlowSnapshotWrite {
    /**
     * 
     * @type {string}
     * @memberof FlowSnapshotWrite
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FlowSnapshotWrite
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof FlowSnapshotWrite
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof FlowSnapshotWrite
     */
    cypress_commands?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlowSnapshotWrite
     */
    attachments?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlowSnapshotWrite
     */
    checks?: Array<string>;
}
/**
 * 
 * @export
 * @interface FlowSnapshotWriteAllOf
 */
export interface FlowSnapshotWriteAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof FlowSnapshotWriteAllOf
     */
    attachments?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlowSnapshotWriteAllOf
     */
    checks?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FormFactorEnum {
    W = 'W',
    M = 'M',
    D = 'D'
}

/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    summary?: string;
    /**
     * 
     * @type {AutoRunStatusEnum}
     * @memberof InlineObject
     */
    status?: AutoRunStatusEnum;
    /**
     * 
     * @type {object}
     * @memberof InlineObject
     */
    stats?: object;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    user_agent?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    created?: string;
    /**
     * 
     * @type {object}
     * @memberof InlineObject
     */
    jobs?: object;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    bundle?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    env_variables?: string;
    /**
     * 
     * @type {Array<FlowSnapshotWrite>}
     * @memberof InlineObject
     */
    snapshots?: Array<FlowSnapshotWrite>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject
     */
    attachments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    finalized_at?: string | null;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    summary?: string;
    /**
     * 
     * @type {AutoRunStatusEnum}
     * @memberof InlineObject1
     */
    status?: AutoRunStatusEnum;
    /**
     * 
     * @type {object}
     * @memberof InlineObject1
     */
    stats?: object;
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    user_agent?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    created?: string;
    /**
     * 
     * @type {object}
     * @memberof InlineObject1
     */
    jobs?: object;
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    bundle?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    env_variables?: string;
    /**
     * 
     * @type {Array<FlowSnapshotRead>}
     * @memberof InlineObject1
     */
    snapshots?: Array<FlowSnapshotRead>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject1
     */
    attachments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    finalized_at?: string | null;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    summary?: string;
    /**
     * 
     * @type {AutoRunStatusEnum}
     * @memberof InlineObject2
     */
    status?: AutoRunStatusEnum;
    /**
     * 
     * @type {object}
     * @memberof InlineObject2
     */
    stats?: object;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    user_agent?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    created?: string;
    /**
     * 
     * @type {object}
     * @memberof InlineObject2
     */
    jobs?: object;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    bundle?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    env_variables?: string;
    /**
     * 
     * @type {Array<FlowSnapshotRead>}
     * @memberof InlineObject2
     */
    snapshots?: Array<FlowSnapshotRead>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject2
     */
    attachments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    finalized_at?: string | null;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject3
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject3
     */
    description?: string;
    /**
     * 
     * @type {Array<Check>}
     * @memberof InlineObject3
     */
    checks?: Array<Check>;
    /**
     * 
     * @type {number}
     * @memberof InlineObject3
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject3
     */
    cypress_commands?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject3
     */
    attachments?: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    scheduled_at?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof InlineObject4
     */
    testers?: Array<number>;
    /**
     * 
     * @type {Array<FlowSnapshotWrite>}
     * @memberof InlineObject4
     */
    snapshots?: Array<FlowSnapshotWrite>;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    state?: string;
    /**
     * 
     * @type {RunStatusEnum}
     * @memberof InlineObject4
     */
    status?: RunStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    summary?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject4
     */
    reports?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    user_agent?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    report_created_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    report_email_schedule_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    selected_environment?: string;
    /**
     * 
     * @type {FormFactorEnum}
     * @memberof InlineObject4
     */
    form_factor?: FormFactorEnum;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    access_instruction?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    env_variables?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    platform?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    email_summary?: string;
}
/**
 * 
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    scheduled_at?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof InlineObject5
     */
    testers?: Array<number>;
    /**
     * 
     * @type {Array<FlowSnapshotRead>}
     * @memberof InlineObject5
     */
    snapshots?: Array<FlowSnapshotRead>;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    state?: string;
    /**
     * 
     * @type {RunStatusEnum}
     * @memberof InlineObject5
     */
    status?: RunStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    summary?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject5
     */
    reports?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    user_agent?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    report_created_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    report_email_schedule_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    selected_environment?: string;
    /**
     * 
     * @type {FormFactorEnum}
     * @memberof InlineObject5
     */
    form_factor?: FormFactorEnum;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    access_instruction?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    env_variables?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    platform?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    email_summary?: string;
}
/**
 * 
 * @export
 * @interface InlineObject6
 */
export interface InlineObject6 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    scheduled_at?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof InlineObject6
     */
    testers?: Array<number>;
    /**
     * 
     * @type {Array<FlowSnapshotWrite>}
     * @memberof InlineObject6
     */
    snapshots?: Array<FlowSnapshotWrite>;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    state?: string;
    /**
     * 
     * @type {RunStatusEnum}
     * @memberof InlineObject6
     */
    status?: RunStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    summary?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject6
     */
    reports?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    user_agent?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    report_created_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    report_email_schedule_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    selected_environment?: string;
    /**
     * 
     * @type {FormFactorEnum}
     * @memberof InlineObject6
     */
    form_factor?: FormFactorEnum;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    access_instruction?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    env_variables?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    platform?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    email_summary?: string;
}
/**
 * 
 * @export
 * @interface InlineObject7
 */
export interface InlineObject7 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject7
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject7
     */
    description?: string;
    /**
     * 
     * @type {Array<Check>}
     * @memberof InlineObject7
     */
    checks?: Array<Check>;
    /**
     * 
     * @type {number}
     * @memberof InlineObject7
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject7
     */
    cypress_commands?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject7
     */
    attachments?: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<ExternalAutoRun>}
     * @memberof InlineResponse200
     */
    results: Array<ExternalAutoRun>;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2001
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<AutoRunJobRead>}
     * @memberof InlineResponse2001
     */
    results: Array<AutoRunJobRead>;
}
/**
 * 
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20010
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20010
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20010
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Project>}
     * @memberof InlineResponse20010
     */
    results: Array<Project>;
}
/**
 * 
 * @export
 * @interface InlineResponse20011
 */
export interface InlineResponse20011 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20011
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20011
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20011
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<ApiKey>}
     * @memberof InlineResponse20011
     */
    results: Array<ApiKey>;
}
/**
 * 
 * @export
 * @interface InlineResponse20012
 */
export interface InlineResponse20012 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20012
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20012
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20012
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<AutoRunList>}
     * @memberof InlineResponse20012
     */
    results: Array<AutoRunList>;
}
/**
 * 
 * @export
 * @interface InlineResponse20013
 */
export interface InlineResponse20013 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20013
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20013
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20013
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Check>}
     * @memberof InlineResponse20013
     */
    results: Array<Check>;
}
/**
 * 
 * @export
 * @interface InlineResponse20014
 */
export interface InlineResponse20014 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20014
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20014
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20014
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Case>}
     * @memberof InlineResponse20014
     */
    results: Array<Case>;
}
/**
 * 
 * @export
 * @interface InlineResponse20015
 */
export interface InlineResponse20015 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20015
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20015
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20015
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Flow>}
     * @memberof InlineResponse20015
     */
    results: Array<Flow>;
}
/**
 * 
 * @export
 * @interface InlineResponse20016
 */
export interface InlineResponse20016 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20016
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20016
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20016
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Recipient>}
     * @memberof InlineResponse20016
     */
    results: Array<Recipient>;
}
/**
 * 
 * @export
 * @interface InlineResponse20017
 */
export interface InlineResponse20017 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20017
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20017
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20017
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<RunList>}
     * @memberof InlineResponse20017
     */
    results: Array<RunList>;
}
/**
 * 
 * @export
 * @interface InlineResponse20018
 */
export interface InlineResponse20018 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20018
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20018
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20018
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<NamedBug>}
     * @memberof InlineResponse20018
     */
    results: Array<NamedBug>;
}
/**
 * 
 * @export
 * @interface InlineResponse20019
 */
export interface InlineResponse20019 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20019
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20019
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20019
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<MessageLog>}
     * @memberof InlineResponse20019
     */
    results: Array<MessageLog>;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2002
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<AutoRunSchedule>}
     * @memberof InlineResponse2002
     */
    results: Array<AutoRunSchedule>;
}
/**
 * 
 * @export
 * @interface InlineResponse20020
 */
export interface InlineResponse20020 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20020
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20020
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20020
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<UserManage>}
     * @memberof InlineResponse20020
     */
    results: Array<UserManage>;
}
/**
 * 
 * @export
 * @interface InlineResponse20021
 */
export interface InlineResponse20021 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20021
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20021
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20021
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<PlatformRead>}
     * @memberof InlineResponse20021
     */
    results: Array<PlatformRead>;
}
/**
 * 
 * @export
 * @interface InlineResponse20022
 */
export interface InlineResponse20022 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20022
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20022
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20022
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Vonage>}
     * @memberof InlineResponse20022
     */
    results: Array<Vonage>;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2003
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<AutoRunView>}
     * @memberof InlineResponse2003
     */
    results: Array<AutoRunView>;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2004
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2004
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2004
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<CheckRetry>}
     * @memberof InlineResponse2004
     */
    results: Array<CheckRetry>;
}
/**
 * 
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2005
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2005
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2005
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<ViewerToken>}
     * @memberof InlineResponse2005
     */
    results: Array<ViewerToken>;
}
/**
 * 
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2006
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2006
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2006
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Order>}
     * @memberof InlineResponse2006
     */
    results: Array<Order>;
}
/**
 * 
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2007
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2007
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2007
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<DashboardRun>}
     * @memberof InlineResponse2007
     */
    results: Array<DashboardRun>;
}
/**
 * 
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2008
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2008
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2008
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Invitation>}
     * @memberof InlineResponse2008
     */
    results: Array<Invitation>;
}
/**
 * 
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2009
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2009
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2009
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Product>}
     * @memberof InlineResponse2009
     */
    results: Array<Product>;
}
/**
 * 
 * @export
 * @interface Inquiry
 */
export interface Inquiry {
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof Inquiry
     */
    body: string;
}
/**
 * 
 * @export
 * @interface Invitation
 */
export interface Invitation {
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    inviter?: string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    sent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    accepted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    last_name?: string;
    /**
     * 
     * @type {Company}
     * @memberof Invitation
     */
    company?: Company;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    role?: InvitationRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    created?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InvitationRoleEnum {
    M = 'M',
    A = 'A'
}

/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    password: string;
}
/**
 * 
 * @export
 * @interface MailgunEvent
 */
export interface MailgunEvent {
    /**
     * 
     * @type {number}
     * @memberof MailgunEvent
     */
    timestamp: number;
    /**
     * 
     * @type {string}
     * @memberof MailgunEvent
     */
    event: string;
    /**
     * 
     * @type {string}
     * @memberof MailgunEvent
     */
    recipient: string;
}
/**
 * 
 * @export
 * @interface MailgunTrackingWebhook
 */
export interface MailgunTrackingWebhook {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MailgunTrackingWebhook
     */
    signature: { [key: string]: string; };
    /**
     * 
     * @type {MailgunEvent}
     * @memberof MailgunTrackingWebhook
     */
    event_data: MailgunEvent;
}
/**
 * 
 * @export
 * @interface MessageLog
 */
export interface MessageLog {
    /**
     * 
     * @type {string}
     * @memberof MessageLog
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof MessageLog
     */
    sender: string;
    /**
     * 
     * @type {string}
     * @memberof MessageLog
     */
    content: string;
}
/**
 * 
 * @export
 * @interface NamedBug
 */
export interface NamedBug {
    /**
     * 
     * @type {string}
     * @memberof NamedBug
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof NamedBug
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NamedBug
     */
    description: string;
}
/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    is_consumed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    details?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    promotion_code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    is_paid?: boolean;
}
/**
 * 
 * @export
 * @interface PasswordChange
 */
export interface PasswordChange {
    /**
     * 
     * @type {string}
     * @memberof PasswordChange
     */
    new_password1: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChange
     */
    new_password2: string;
}
/**
 * 
 * @export
 * @interface PasswordReset
 */
export interface PasswordReset {
    /**
     * 
     * @type {string}
     * @memberof PasswordReset
     */
    email: string;
}
/**
 * 
 * @export
 * @interface PasswordResetConfirm
 */
export interface PasswordResetConfirm {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetConfirm
     */
    new_password1: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetConfirm
     */
    new_password2: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetConfirm
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetConfirm
     */
    token: string;
}
/**
 * 
 * @export
 * @interface Platform
 */
export interface Platform {
    /**
     * 
     * @type {string}
     * @memberof Platform
     */
    id?: string;
    /**
     * 
     * @type {FormFactorEnum}
     * @memberof Platform
     */
    form_factor: FormFactorEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Platform
     */
    available_environment?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Platform
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Platform
     */
    access_instruction?: string;
    /**
     * 
     * @type {string}
     * @memberof Platform
     */
    env_variables?: string;
}
/**
 * 
 * @export
 * @interface PlatformRead
 */
export interface PlatformRead {
    /**
     * 
     * @type {string}
     * @memberof PlatformRead
     */
    id?: string;
    /**
     * 
     * @type {FormFactorEnum}
     * @memberof PlatformRead
     */
    form_factor: FormFactorEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlatformRead
     */
    available_environment?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PlatformRead
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PlatformRead
     */
    access_instruction?: string;
    /**
     * 
     * @type {string}
     * @memberof PlatformRead
     */
    env_variables?: string;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof PlatformRead
     */
    attachments?: Array<Attachment>;
}
/**
 * 
 * @export
 * @interface PlatformReadAllOf
 */
export interface PlatformReadAllOf {
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof PlatformReadAllOf
     */
    attachments?: Array<Attachment>;
}
/**
 * 
 * @export
 * @interface PlatformWrite
 */
export interface PlatformWrite {
    /**
     * 
     * @type {string}
     * @memberof PlatformWrite
     */
    id?: string;
    /**
     * 
     * @type {FormFactorEnum}
     * @memberof PlatformWrite
     */
    form_factor: FormFactorEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlatformWrite
     */
    available_environment?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PlatformWrite
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PlatformWrite
     */
    access_instruction?: string;
    /**
     * 
     * @type {string}
     * @memberof PlatformWrite
     */
    env_variables?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlatformWrite
     */
    attachments?: Array<string>;
}
/**
 * 
 * @export
 * @interface PlatformWriteAllOf
 */
export interface PlatformWriteAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof PlatformWriteAllOf
     */
    attachments?: Array<string>;
}
/**
 * 
 * @export
 * @interface Price
 */
export interface Price {
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    id: string;
    /**
     * Three-letter ISO currency code
     * @type {string}
     * @memberof Price
     */
    currency: string;
    /**
     * The unit amount in cents to be charged, represented as a whole integer if possible. Null if a sub-cent precision is required.
     * @type {number}
     * @memberof Price
     */
    unit_amount?: number | null;
    /**
     * The recurring components of a price such as `interval` and `usage_type`.
     * @type {string}
     * @memberof Price
     */
    recurring?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    period?: number;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    minimum_term?: string;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    id: string;
    /**
     * The product\'s name, meant to be displayable to the customer. Applicable to both `service` and `good` types.
     * @type {string}
     * @memberof Product
     */
    name: string;
    /**
     * 
     * @type {Array<Price>}
     * @memberof Product
     */
    prices: Array<Price>;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    is_active?: boolean;
    /**
     * 
     * @type {Subscription}
     * @memberof Project
     */
    subscription?: Subscription;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    created?: string;
    /**
     * 
     * @type {Array<PlatformRead>}
     * @memberof Project
     */
    platforms?: Array<PlatformRead>;
    /**
     * 
     * @type {Avatar}
     * @memberof Project
     */
    avatar?: Avatar | null;
    /**
     * 
     * @type {object}
     * @memberof Project
     */
    testers?: object;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    is_bookmarked?: boolean;
}
/**
 * 
 * @export
 * @interface Recipient
 */
export interface Recipient {
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    email_autorun_notifications?: RecipientEmailAutorunNotificationsEnum;
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    email_run_notifications?: RecipientEmailRunNotificationsEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RecipientEmailAutorunNotificationsEnum {
    Y = 'Y',
    N = 'N',
    A = 'A'
}
/**
    * @export
    * @enum {string}
    */
export enum RecipientEmailRunNotificationsEnum {
    Y = 'Y',
    N = 'N'
}

/**
 * 
 * @export
 * @interface Register
 */
export interface Register {
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    password1: string;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    password2: string;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    last_name: string;
    /**
     * 
     * @type {Company}
     * @memberof Register
     */
    company?: Company;
    /**
     * 
     * @type {Avatar}
     * @memberof Register
     */
    avatar?: Avatar | null;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    token?: string;
}
/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    file?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface ResendEmailVerification
 */
export interface ResendEmailVerification {
    /**
     * 
     * @type {string}
     * @memberof ResendEmailVerification
     */
    email: string;
}
/**
 * 
 * @export
 * @interface Run
 */
export interface Run {
    /**
     * 
     * @type {string}
     * @memberof Run
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Run
     */
    scheduled_at?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof Run
     */
    testers?: Array<number>;
    /**
     * 
     * @type {Array<FlowSnapshotRead>}
     * @memberof Run
     */
    snapshots?: Array<FlowSnapshotRead>;
    /**
     * 
     * @type {string}
     * @memberof Run
     */
    state?: string;
    /**
     * 
     * @type {RunStatusEnum}
     * @memberof Run
     */
    status?: RunStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Run
     */
    summary?: string;
    /**
     * 
     * @type {Array<AutoRunAttachments>}
     * @memberof Run
     */
    reports?: Array<AutoRunAttachments>;
    /**
     * 
     * @type {string}
     * @memberof Run
     */
    user_agent?: string;
    /**
     * 
     * @type {string}
     * @memberof Run
     */
    report_created_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Run
     */
    report_email_schedule_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Run
     */
    selected_environment?: string;
    /**
     * 
     * @type {FormFactorEnum}
     * @memberof Run
     */
    form_factor?: FormFactorEnum;
    /**
     * 
     * @type {string}
     * @memberof Run
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Run
     */
    access_instruction?: string;
    /**
     * 
     * @type {string}
     * @memberof Run
     */
    env_variables?: string;
    /**
     * 
     * @type {string}
     * @memberof Run
     */
    platform?: string;
    /**
     * 
     * @type {string}
     * @memberof Run
     */
    email_summary?: string;
}
/**
 * 
 * @export
 * @interface RunCSV
 */
export interface RunCSV {
    /**
     * 
     * @type {string}
     * @memberof RunCSV
     */
    snapshot?: string;
    /**
     * 
     * @type {string}
     * @memberof RunCSV
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof RunCSV
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RunCSV
     */
    comment?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RunCSV
     */
    result_attachments?: Array<string>;
}
/**
 * 
 * @export
 * @interface RunList
 */
export interface RunList {
    /**
     * 
     * @type {string}
     * @memberof RunList
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RunList
     */
    scheduled_at?: string | null;
    /**
     * 
     * @type {Array<Tester>}
     * @memberof RunList
     */
    testers: Array<Tester>;
    /**
     * 
     * @type {string}
     * @memberof RunList
     */
    state?: string;
    /**
     * 
     * @type {RunStatusEnum}
     * @memberof RunList
     */
    status?: RunStatusEnum;
    /**
     * 
     * @type {object}
     * @memberof RunList
     */
    stats?: object;
    /**
     * 
     * @type {Array<Report>}
     * @memberof RunList
     */
    reports: Array<Report>;
    /**
     * 
     * @type {string}
     * @memberof RunList
     */
    report_created_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RunList
     */
    user_agent?: string;
    /**
     * 
     * @type {string}
     * @memberof RunList
     */
    selected_environment?: string;
    /**
     * 
     * @type {FormFactorEnum}
     * @memberof RunList
     */
    form_factor?: FormFactorEnum;
    /**
     * 
     * @type {string}
     * @memberof RunList
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RunList
     */
    access_instruction?: string;
    /**
     * 
     * @type {string}
     * @memberof RunList
     */
    env_variables?: string;
}
/**
 * 
 * @export
 * @interface RunStats
 */
export interface RunStats {
    /**
     * 
     * @type {string}
     * @memberof RunStats
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RunStats
     */
    scheduled_at?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RunStats
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof RunStats
     */
    failed?: number;
    /**
     * 
     * @type {number}
     * @memberof RunStats
     */
    passed?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum RunStatusEnum {
    P = 'P',
    S = 'S',
    F = 'F',
    U = 'U'
}

/**
 * 
 * @export
 * @interface RunView
 */
export interface RunView {
    /**
     * 
     * @type {string}
     * @memberof RunView
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RunView
     */
    scheduled_at?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RunView
     */
    testers?: Array<number>;
    /**
     * 
     * @type {Array<FlowSnapshotRead>}
     * @memberof RunView
     */
    snapshots?: Array<FlowSnapshotRead>;
    /**
     * 
     * @type {string}
     * @memberof RunView
     */
    state?: string;
    /**
     * 
     * @type {RunStatusEnum}
     * @memberof RunView
     */
    status?: RunStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof RunView
     */
    summary?: string;
    /**
     * 
     * @type {Array<AutoRunAttachments>}
     * @memberof RunView
     */
    reports?: Array<AutoRunAttachments>;
    /**
     * 
     * @type {string}
     * @memberof RunView
     */
    user_agent?: string;
    /**
     * 
     * @type {string}
     * @memberof RunView
     */
    report_created_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RunView
     */
    report_email_schedule_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RunView
     */
    selected_environment?: string;
    /**
     * 
     * @type {FormFactorEnum}
     * @memberof RunView
     */
    form_factor?: FormFactorEnum;
    /**
     * 
     * @type {string}
     * @memberof RunView
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RunView
     */
    access_instruction?: string;
    /**
     * 
     * @type {string}
     * @memberof RunView
     */
    env_variables?: string;
    /**
     * 
     * @type {string}
     * @memberof RunView
     */
    platform?: string;
    /**
     * 
     * @type {string}
     * @memberof RunView
     */
    email_summary?: string;
    /**
     * 
     * @type {object}
     * @memberof RunView
     */
    project?: object;
}
/**
 * 
 * @export
 * @interface SESWebhook
 */
export interface SESWebhook {
    /**
     * 
     * @type {string}
     * @memberof SESWebhook
     */
    mail: string;
    /**
     * 
     * @type {string}
     * @memberof SESWebhook
     */
    content: string;
}
/**
 * 
 * @export
 * @interface SessionRequest
 */
export interface SessionRequest {
    /**
     * 
     * @type {string}
     * @memberof SessionRequest
     */
    price: string;
    /**
     * 
     * @type {string}
     * @memberof SessionRequest
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    id: string;
    /**
     * Start of the current period for which the subscription has been invoiced.
     * @type {string}
     * @memberof Subscription
     */
    current_period_start: string;
    /**
     * End of the current period for which the subscription has been invoiced. At the end of this period, a new invoice will be created.
     * @type {string}
     * @memberof Subscription
     */
    current_period_end: string;
    /**
     * Date when the subscription was first created. The date might differ from the created date due to backdating.
     * @type {string}
     * @memberof Subscription
     */
    start_date?: string | null;
    /**
     * The status of this subscription.
     * @type {string}
     * @memberof Subscription
     */
    status: SubscriptionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    price?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SubscriptionStatusEnum {
    Active = 'active',
    Canceled = 'canceled',
    Incomplete = 'incomplete',
    IncompleteExpired = 'incomplete_expired',
    PastDue = 'past_due',
    Trialing = 'trialing',
    Unpaid = 'unpaid'
}

/**
 * 
 * @export
 * @interface Tester
 */
export interface Tester {
    /**
     * 
     * @type {string}
     * @memberof Tester
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Tester
     */
    name: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    last_name?: string;
    /**
     * 
     * @type {Company}
     * @memberof User
     */
    company?: Company;
    /**
     * 
     * @type {Avatar}
     * @memberof User
     */
    avatar?: Avatar | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    has_verified_email?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    role?: UserRoleEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UserRoleEnum {
    M = 'M',
    A = 'A'
}

/**
 * 
 * @export
 * @interface UserManage
 */
export interface UserManage {
    /**
     * 
     * @type {string}
     * @memberof UserManage
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserManage
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserManage
     */
    last_name?: string;
    /**
     * 
     * @type {Avatar}
     * @memberof UserManage
     */
    avatar?: Avatar | null;
    /**
     * 
     * @type {string}
     * @memberof UserManage
     */
    role?: UserManageRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof UserManage
     */
    email?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserManageRoleEnum {
    M = 'M',
    A = 'A'
}

/**
 * 
 * @export
 * @interface VerifyEmail
 */
export interface VerifyEmail {
    /**
     * 
     * @type {string}
     * @memberof VerifyEmail
     */
    key: string;
}
/**
 * 
 * @export
 * @interface ViewerToken
 */
export interface ViewerToken {
    /**
     * 
     * @type {string}
     * @memberof ViewerToken
     */
    key?: string;
}
/**
 * 
 * @export
 * @interface Vonage
 */
export interface Vonage {
    /**
     * 
     * @type {string}
     * @memberof Vonage
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof Vonage
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof Vonage
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof Vonage
     */
    timestamp?: string;
}

/**
 * ApiApi - axios parameter creator
 * @export
 */
export const ApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lease auto run for `AUTORUN_LEASED_TIME` minutes at the time
         * @param {string} id 
         * @param {AutoRunJobRead} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsJobsAck: async (id: string, data: AutoRunJobRead, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AutoRunsJobsAck', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1AutoRunsJobsAck', 'data', data)
            const localVarPath = `/api/v1/auto-runs/jobs/{id}/ack/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AutoRunJobWrite} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsJobsCreate: async (data: AutoRunJobWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1AutoRunsJobsCreate', 'data', data)
            const localVarPath = `/api/v1/auto-runs/jobs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsJobsDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AutoRunsJobsDelete', 'id', id)
            const localVarPath = `/api/v1/auto-runs/jobs/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lease leased auto run for `AUTORUN_LEASED_TIME` minutes at the time
         * @param {string} id 
         * @param {AutoRunJobRead} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsJobsExtendLease: async (id: string, data: AutoRunJobRead, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AutoRunsJobsExtendLease', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1AutoRunsJobsExtendLease', 'data', data)
            const localVarPath = `/api/v1/auto-runs/jobs/{id}/extend-lease/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [isAvailable] is_available
         * @param {'W' | 'P' | 'C'} [status] status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsJobsList: async (isAvailable?: string, status?: 'W' | 'P' | 'C', limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auto-runs/jobs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isAvailable !== undefined) {
                localVarQueryParameter['is_available'] = isAvailable;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Make auto run available
         * @param {string} id 
         * @param {AutoRunJobRead} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsJobsNack: async (id: string, data: AutoRunJobRead, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AutoRunsJobsNack', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1AutoRunsJobsNack', 'data', data)
            const localVarPath = `/api/v1/auto-runs/jobs/{id}/nack/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AutoRunJobRead} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsJobsPartialUpdate: async (id: string, data: AutoRunJobRead, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AutoRunsJobsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1AutoRunsJobsPartialUpdate', 'data', data)
            const localVarPath = `/api/v1/auto-runs/jobs/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsJobsRead: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AutoRunsJobsRead', 'id', id)
            const localVarPath = `/api/v1/auto-runs/jobs/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {AutoRunJobWrite} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsJobsUpdate: async (id: string, data: AutoRunJobWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AutoRunsJobsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1AutoRunsJobsUpdate', 'data', data)
            const localVarPath = `/api/v1/auto-runs/jobs/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'' | 'S' | 'F'} [status] status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsList: async (status?: '' | 'S' | 'F', limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auto-runs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} checkId 
         * @param {CheckRetry} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsRetryCreate: async (id: string, checkId: string, data: CheckRetry, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AutoRunsRetryCreate', 'id', id)
            // verify required parameter 'checkId' is not null or undefined
            assertParamExists('apiV1AutoRunsRetryCreate', 'checkId', checkId)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1AutoRunsRetryCreate', 'data', data)
            const localVarPath = `/api/v1/auto-runs/{id}/retry/{check_id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"check_id"}}`, encodeURIComponent(String(checkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return check retry list.
         * @param {string} id 
         * @param {string} checkId 
         * @param {'' | 'S' | 'F'} [status] status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsRetryRead: async (id: string, checkId: string, status?: '' | 'S' | 'F', limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AutoRunsRetryRead', 'id', id)
            // verify required parameter 'checkId' is not null or undefined
            assertParamExists('apiV1AutoRunsRetryRead', 'checkId', checkId)
            const localVarPath = `/api/v1/auto-runs/{id}/retry/{check_id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"check_id"}}`, encodeURIComponent(String(checkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AutoRunSchedule} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsScheduleCreate: async (data: AutoRunSchedule, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1AutoRunsScheduleCreate', 'data', data)
            const localVarPath = `/api/v1/auto-runs/schedule/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Auto Run Schedule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsScheduleDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AutoRunsScheduleDelete', 'id', id)
            const localVarPath = `/api/v1/auto-runs/schedule/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsScheduleList: async (limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auto-runs/schedule/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Auto Run Schedule.
         * @param {AutoRunSchedule} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsSchedulePartialUpdate: async (id: string, data: AutoRunSchedule, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AutoRunsSchedulePartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1AutoRunsSchedulePartialUpdate', 'data', data)
            const localVarPath = `/api/v1/auto-runs/schedule/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Auto Run Schedule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsScheduleRead: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AutoRunsScheduleRead', 'id', id)
            const localVarPath = `/api/v1/auto-runs/schedule/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Auto Run Schedule.
         * @param {AutoRunSchedule} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsScheduleUpdate: async (id: string, data: AutoRunSchedule, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AutoRunsScheduleUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1AutoRunsScheduleUpdate', 'data', data)
            const localVarPath = `/api/v1/auto-runs/schedule/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve autorun by viewer token
         * @param {string} token 
         * @param {'' | 'S' | 'F'} [status] status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsViewAutorun: async (token: string, status?: '' | 'S' | 'F', limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('apiV1AutoRunsViewAutorun', 'token', token)
            const localVarPath = `/api/v1/auto-runs/view/{token}/`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} runId 
         * @param {ViewerToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsViewerTokensCreate: async (runId: string, data: ViewerToken, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1AutoRunsViewerTokensCreate', 'runId', runId)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1AutoRunsViewerTokensCreate', 'data', data)
            const localVarPath = `/api/v1/auto-runs/{run_id}/viewer-tokens/`
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsViewerTokensDelete: async (runId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1AutoRunsViewerTokensDelete', 'runId', runId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1AutoRunsViewerTokensDelete', 'id', id)
            const localVarPath = `/api/v1/auto-runs/{run_id}/viewer-tokens/{id}/`
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} runId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsViewerTokensList: async (runId: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1AutoRunsViewerTokensList', 'runId', runId)
            const localVarPath = `/api/v1/auto-runs/{run_id}/viewer-tokens/`
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SessionRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CheckoutCreate: async (data: SessionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1CheckoutCreate', 'data', data)
            const localVarPath = `/api/v1/checkout/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CheckoutList: async (limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/checkout/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Order} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CheckoutPartialUpdate: async (id: string, data: Order, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1CheckoutPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1CheckoutPartialUpdate', 'data', data)
            const localVarPath = `/api/v1/checkout/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Order} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CheckoutUpdate: async (id: string, data: Order, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1CheckoutUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1CheckoutUpdate', 'data', data)
            const localVarPath = `/api/v1/checkout/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DashboardRunsList: async (limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/runs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} file 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesCreate: async (file: any, description?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiV1FilesCreate', 'file', file)
            const localVarPath = `/api/v1/files/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAttachmentFromURL} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesCreateFromUrl: async (data: CreateAttachmentFromURL, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1FilesCreateFromUrl', 'data', data)
            const localVarPath = `/api/v1/files/create-from-url/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Attachment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1FilesDelete', 'id', id)
            const localVarPath = `/api/v1/files/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Inquiry} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InquiryCreate: async (data: Inquiry, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1InquiryCreate', 'data', data)
            const localVarPath = `/api/v1/inquiry/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Invitation} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitationsCreate: async (data: Invitation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1InvitationsCreate', 'data', data)
            const localVarPath = `/api/v1/invitations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitationsDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1InvitationsDelete', 'id', id)
            const localVarPath = `/api/v1/invitations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitationsList: async (limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/invitations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitationsRead: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1InvitationsRead', 'id', id)
            const localVarPath = `/api/v1/invitations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Invitation} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitationsResend: async (id: string, data: Invitation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1InvitationsResend', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1InvitationsResend', 'data', data)
            const localVarPath = `/api/v1/invitations/{id}/resend/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
         * @param {Login} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LoginCreate: async (data: Login, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1LoginCreate', 'data', data)
            const localVarPath = `/api/v1/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Accepts/Returns nothing.
         * @summary Calls Django logout method and delete the Token object assigned to the current User object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LogoutCreate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/logout/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Accepts/Returns nothing.
         * @summary Calls Django logout method and delete the Token object assigned to the current User object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LogoutList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/logout/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeRead: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/me/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
         * @summary Calls Django Auth SetPasswordForm save method.
         * @param {PasswordChange} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PasswordChangeCreate: async (data: PasswordChange, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1PasswordChangeCreate', 'data', data)
            const localVarPath = `/api/v1/password/change/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
         * @summary Password reset e-mail link is confirmed, therefore this resets the user\'s password.
         * @param {PasswordResetConfirm} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PasswordResetConfirmCreate: async (data: PasswordResetConfirm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1PasswordResetConfirmCreate', 'data', data)
            const localVarPath = `/api/v1/password/reset/confirm/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Accepts the following POST parameters: email Returns the success/fail message.
         * @summary Calls Django Auth PasswordResetForm save method.
         * @param {PasswordReset} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PasswordResetCreate: async (data: PasswordReset, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1PasswordResetCreate', 'data', data)
            const localVarPath = `/api/v1/password/reset/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {PlatformWrite} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PlatformsCreate: async (projectId: string, data: PlatformWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1PlatformsCreate', 'projectId', projectId)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1PlatformsCreate', 'data', data)
            const localVarPath = `/api/v1/{project_id}/platforms/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PlatformsDelete: async (projectId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1PlatformsDelete', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PlatformsDelete', 'id', id)
            const localVarPath = `/api/v1/{project_id}/platforms/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PlatformsList: async (projectId: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1PlatformsList', 'projectId', projectId)
            const localVarPath = `/api/v1/{project_id}/platforms/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {PlatformWrite} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PlatformsPartialUpdate: async (projectId: string, id: string, data: PlatformWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1PlatformsPartialUpdate', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PlatformsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1PlatformsPartialUpdate', 'data', data)
            const localVarPath = `/api/v1/{project_id}/platforms/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PlatformsRead: async (projectId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1PlatformsRead', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PlatformsRead', 'id', id)
            const localVarPath = `/api/v1/{project_id}/platforms/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {PlatformWrite} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PlatformsUpdate: async (projectId: string, id: string, data: PlatformWrite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1PlatformsUpdate', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1PlatformsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1PlatformsUpdate', 'data', data)
            const localVarPath = `/api/v1/{project_id}/platforms/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductsList: async (limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ApiKey} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsApiKeysCreate: async (projectId: string, data: ApiKey, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsApiKeysCreate', 'projectId', projectId)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsApiKeysCreate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/api-keys/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsApiKeysDelete: async (projectId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsApiKeysDelete', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsApiKeysDelete', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/api-keys/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsApiKeysList: async (projectId: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsApiKeysList', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/api-keys/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsChecksDelete: async (projectId: string, runId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsChecksDelete', 'projectId', projectId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsChecksDelete', 'runId', runId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsChecksDelete', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/auto-runs/{run_id}/checks/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} [tags] tags
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsChecksList: async (projectId: string, runId: string, tags?: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsChecksList', 'projectId', projectId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsChecksList', 'runId', runId)
            const localVarPath = `/api/v1/projects/{project_id}/auto-runs/{run_id}/checks/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {Check} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsChecksPartialUpdate: async (projectId: string, runId: string, id: string, data: Check, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsChecksPartialUpdate', 'projectId', projectId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsChecksPartialUpdate', 'runId', runId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsChecksPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsChecksPartialUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/auto-runs/{run_id}/checks/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsChecksRead: async (projectId: string, runId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsChecksRead', 'projectId', projectId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsChecksRead', 'runId', runId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsChecksRead', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/auto-runs/{run_id}/checks/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {Check} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsChecksUpdate: async (projectId: string, runId: string, id: string, data: Check, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsChecksUpdate', 'projectId', projectId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsChecksUpdate', 'runId', runId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsChecksUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsChecksUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/auto-runs/{run_id}/checks/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {InlineObject} data 
         * @param {Array<string>} [tags] 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsCreate: async (projectId: string, data: InlineObject, tags?: Array<string>, keyword?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsCreate', 'projectId', projectId)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsCreate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/auto-runs/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (tags) {
                localVarQueryParameter['tags'] = tags.join(COLLECTION_FORMATS.csv);
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsDelete: async (projectId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsDelete', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsDelete', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/auto-runs/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsList: async (projectId: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsList', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/auto-runs/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {InlineObject2} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsPartialUpdate: async (projectId: string, id: string, data: InlineObject2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsPartialUpdate', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsPartialUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/auto-runs/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsRead: async (projectId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsRead', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsRead', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/auto-runs/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create Flow and Cases for corresponded project, Copy Flow to snapshot/checks objects and add them to corresponded Run
         * @param {string} projectId 
         * @param {string} runId 
         * @param {Flow} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsSnapshotsCreate: async (projectId: string, runId: string, data: Flow, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsSnapshotsCreate', 'projectId', projectId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsSnapshotsCreate', 'runId', runId)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsSnapshotsCreate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/auto-runs/{run_id}/snapshots/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create Case for corresponded flow/project Copy the Case to new Check and add it to corresponded snapshot
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {FlowSnapshotRead} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsSnapshotsCreateCheck: async (projectId: string, runId: string, id: string, data: FlowSnapshotRead, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsSnapshotsCreateCheck', 'projectId', projectId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsSnapshotsCreateCheck', 'runId', runId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsSnapshotsCreateCheck', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsSnapshotsCreateCheck', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/auto-runs/{run_id}/snapshots/{id}/create-check/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {InlineObject3} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsSnapshotsPartialUpdate: async (projectId: string, runId: string, id: string, data: InlineObject3, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsSnapshotsPartialUpdate', 'projectId', projectId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsSnapshotsPartialUpdate', 'runId', runId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsSnapshotsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsSnapshotsPartialUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/auto-runs/{run_id}/snapshots/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsSnapshotsRead: async (projectId: string, runId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsSnapshotsRead', 'projectId', projectId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsSnapshotsRead', 'runId', runId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsSnapshotsRead', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/auto-runs/{run_id}/snapshots/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {InlineObject1} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsUpdate: async (projectId: string, id: string, data: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsUpdate', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsAutoRunsUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/auto-runs/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Project.
         * @param {Project} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsBookmarkCreate: async (id: number, data: Project, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsBookmarkCreate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsBookmarkCreate', 'data', data)
            const localVarPath = `/api/v1/projects/{id}/bookmark/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsBookmarkDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsBookmarkDelete', 'id', id)
            const localVarPath = `/api/v1/projects/{id}/bookmark/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Case} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsCasesCreate: async (projectId: string, data: Case, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsCasesCreate', 'projectId', projectId)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsCasesCreate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/cases/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsCasesDelete: async (projectId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsCasesDelete', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsCasesDelete', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/cases/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {string} [tags] tags
         * @param {string} [keyword] keyword
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsCasesHistory: async (projectId: string, id: string, tags?: string, keyword?: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsCasesHistory', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsCasesHistory', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/cases/{id}/history/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {Case} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsCasesPartialUpdate: async (projectId: string, id: string, data: Case, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsCasesPartialUpdate', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsCasesPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsCasesPartialUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/cases/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsCasesRead: async (projectId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsCasesRead', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsCasesRead', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/cases/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsCasesStatistics: async (projectId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsCasesStatistics', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsCasesStatistics', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/cases/{id}/statistics/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {Case} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsCasesUpdate: async (projectId: string, id: string, data: Case, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsCasesUpdate', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsCasesUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsCasesUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/cases/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Project} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsCreate: async (data: Project, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsCreate', 'data', data)
            const localVarPath = `/api/v1/projects/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsDelete', 'id', id)
            const localVarPath = `/api/v1/projects/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [tags] tags
         * @param {string} [keyword] keyword
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsFlowCasesList: async (projectId: string, tags?: string, keyword?: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsFlowCasesList', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/cases/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Flow} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsFlowsCreate: async (projectId: string, data: Flow, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsFlowsCreate', 'projectId', projectId)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsFlowsCreate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/flows/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsFlowsDelete: async (projectId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsFlowsDelete', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsFlowsDelete', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/flows/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsFlowsList: async (projectId: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsFlowsList', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/flows/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {Flow} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsFlowsPartialUpdate: async (projectId: string, id: string, data: Flow, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsFlowsPartialUpdate', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsFlowsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsFlowsPartialUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/flows/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsFlowsRead: async (projectId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsFlowsRead', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsFlowsRead', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/flows/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {Flow} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsFlowsUpdate: async (projectId: string, id: string, data: Flow, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsFlowsUpdate', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsFlowsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsFlowsUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/flows/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [isActive] is_active
         * @param {string} [keyword] keyword
         * @param {string} [bookmark] bookmark
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsList: async (isActive?: string, keyword?: string, bookmark?: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/projects/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (bookmark !== undefined) {
                localVarQueryParameter['bookmark'] = bookmark;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Project.
         * @param {Project} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsPartialUpdate: async (id: string, data: Project, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsPartialUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRead: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRead', 'id', id)
            const localVarPath = `/api/v1/projects/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Recipient} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRecipientsCreate: async (projectId: string, data: Recipient, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRecipientsCreate', 'projectId', projectId)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsRecipientsCreate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/recipients/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRecipientsDelete: async (projectId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRecipientsDelete', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRecipientsDelete', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/recipients/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRecipientsList: async (projectId: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRecipientsList', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/recipients/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {Recipient} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRecipientsPartialUpdate: async (projectId: string, id: string, data: Recipient, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRecipientsPartialUpdate', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRecipientsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsRecipientsPartialUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/recipients/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRecipientsRead: async (projectId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRecipientsRead', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRecipientsRead', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/recipients/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {Recipient} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRecipientsUpdate: async (projectId: string, id: string, data: Recipient, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRecipientsUpdate', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRecipientsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsRecipientsUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/recipients/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} caseId 
         * @param {NamedBug} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsBugsCreate: async (projectId: string, caseId: string, data: NamedBug, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsBugsCreate', 'projectId', projectId)
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsBugsCreate', 'caseId', caseId)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsRunsBugsCreate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{case_id}/bugs/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"case_id"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} caseId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsBugsDelete: async (projectId: string, caseId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsBugsDelete', 'projectId', projectId)
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsBugsDelete', 'caseId', caseId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRunsBugsDelete', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{case_id}/bugs/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"case_id"}}`, encodeURIComponent(String(caseId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} caseId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsBugsList: async (projectId: string, caseId: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsBugsList', 'projectId', projectId)
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsBugsList', 'caseId', caseId)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{case_id}/bugs/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"case_id"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} caseId 
         * @param {string} id 
         * @param {NamedBug} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsBugsPartialUpdate: async (projectId: string, caseId: string, id: string, data: NamedBug, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsBugsPartialUpdate', 'projectId', projectId)
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsBugsPartialUpdate', 'caseId', caseId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRunsBugsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsRunsBugsPartialUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{case_id}/bugs/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"case_id"}}`, encodeURIComponent(String(caseId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} caseId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsBugsRead: async (projectId: string, caseId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsBugsRead', 'projectId', projectId)
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsBugsRead', 'caseId', caseId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRunsBugsRead', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{case_id}/bugs/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"case_id"}}`, encodeURIComponent(String(caseId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} caseId 
         * @param {string} id 
         * @param {NamedBug} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsBugsUpdate: async (projectId: string, caseId: string, id: string, data: NamedBug, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsBugsUpdate', 'projectId', projectId)
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsBugsUpdate', 'caseId', caseId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRunsBugsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsRunsBugsUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{case_id}/bugs/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"case_id"}}`, encodeURIComponent(String(caseId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsChecksDelete: async (projectId: string, runId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsChecksDelete', 'projectId', projectId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsChecksDelete', 'runId', runId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRunsChecksDelete', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{run_id}/checks/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} [tags] tags
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsChecksList: async (projectId: string, runId: string, tags?: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsChecksList', 'projectId', projectId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsChecksList', 'runId', runId)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{run_id}/checks/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {Check} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsChecksPartialUpdate: async (projectId: string, runId: string, id: string, data: Check, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsChecksPartialUpdate', 'projectId', projectId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsChecksPartialUpdate', 'runId', runId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRunsChecksPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsRunsChecksPartialUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{run_id}/checks/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsChecksRead: async (projectId: string, runId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsChecksRead', 'projectId', projectId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsChecksRead', 'runId', runId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRunsChecksRead', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{run_id}/checks/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {Check} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsChecksUpdate: async (projectId: string, runId: string, id: string, data: Check, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsChecksUpdate', 'projectId', projectId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsChecksUpdate', 'runId', runId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRunsChecksUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsRunsChecksUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{run_id}/checks/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {InlineObject4} data 
         * @param {Array<string>} [tags] 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsCreate: async (projectId: string, data: InlineObject4, tags?: Array<string>, keyword?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsCreate', 'projectId', projectId)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsRunsCreate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/runs/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (tags) {
                localVarQueryParameter['tags'] = tags.join(COLLECTION_FORMATS.csv);
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsCsv: async (projectId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsCsv', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRunsCsv', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{id}/csv/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsDelete: async (projectId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsDelete', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRunsDelete', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {'P' | 'S' | 'C'} [state] state
         * @param {string} [hasReports] has_reports
         * @param {string} [reportCreatedAt] report_created_at
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsList: async (projectId: string, state?: 'P' | 'S' | 'C', hasReports?: string, reportCreatedAt?: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsList', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/runs/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (hasReports !== undefined) {
                localVarQueryParameter['has_reports'] = hasReports;
            }

            if (reportCreatedAt !== undefined) {
                localVarQueryParameter['report_created_at'] = reportCreatedAt;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {InlineObject6} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsPartialUpdate: async (projectId: string, id: string, data: InlineObject6, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsPartialUpdate', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRunsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsRunsPartialUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsRead: async (projectId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsRead', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRunsRead', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create Flow and Cases for corresponded project, Copy Flow to snapshot/checks objects and add them to corresponded Run
         * @param {string} projectId 
         * @param {string} runId 
         * @param {Flow} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsSnapshotsCreate: async (projectId: string, runId: string, data: Flow, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsSnapshotsCreate', 'projectId', projectId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsSnapshotsCreate', 'runId', runId)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsRunsSnapshotsCreate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{run_id}/snapshots/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create Case for corresponded flow/project Copy the Case to new Check and add it to corresponded snapshot
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {FlowSnapshotRead} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsSnapshotsCreateCheck: async (projectId: string, runId: string, id: string, data: FlowSnapshotRead, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsSnapshotsCreateCheck', 'projectId', projectId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsSnapshotsCreateCheck', 'runId', runId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRunsSnapshotsCreateCheck', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsRunsSnapshotsCreateCheck', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{run_id}/snapshots/{id}/create-check/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {InlineObject7} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsSnapshotsPartialUpdate: async (projectId: string, runId: string, id: string, data: InlineObject7, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsSnapshotsPartialUpdate', 'projectId', projectId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsSnapshotsPartialUpdate', 'runId', runId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRunsSnapshotsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsRunsSnapshotsPartialUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{run_id}/snapshots/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsSnapshotsRead: async (projectId: string, runId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsSnapshotsRead', 'projectId', projectId)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsSnapshotsRead', 'runId', runId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRunsSnapshotsRead', 'id', id)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{run_id}/snapshots/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {InlineObject5} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsUpdate: async (projectId: string, id: string, data: InlineObject5, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('apiV1ProjectsRunsUpdate', 'projectId', projectId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsRunsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsRunsUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{project_id}/runs/{id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsStatistics: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsStatistics', 'id', id)
            const localVarPath = `/api/v1/projects/{id}/statistics/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Project.
         * @param {Project} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsUpdate: async (id: string, data: Project, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ProjectsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1ProjectsUpdate', 'data', data)
            const localVarPath = `/api/v1/projects/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Register} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RegistrationCreate: async (data: Register, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1RegistrationCreate', 'data', data)
            const localVarPath = `/api/v1/registration/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResendEmailVerification} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RegistrationResendEmailCreate: async (data: ResendEmailVerification, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1RegistrationResendEmailCreate', 'data', data)
            const localVarPath = `/api/v1/registration/resend-email/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyEmail} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RegistrationVerifyEmailCreate: async (data: VerifyEmail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1RegistrationVerifyEmailCreate', 'data', data)
            const localVarPath = `/api/v1/registration/verify-email/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve run by viewer token
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RunsViewRead: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('apiV1RunsViewRead', 'token', token)
            const localVarPath = `/api/v1/runs/view/{token}/`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} runId 
         * @param {ViewerToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RunsViewerTokensCreate: async (runId: string, data: ViewerToken, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1RunsViewerTokensCreate', 'runId', runId)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1RunsViewerTokensCreate', 'data', data)
            const localVarPath = `/api/v1/runs/{run_id}/viewer-tokens/`
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RunsViewerTokensDelete: async (runId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1RunsViewerTokensDelete', 'runId', runId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1RunsViewerTokensDelete', 'id', id)
            const localVarPath = `/api/v1/runs/{run_id}/viewer-tokens/{id}/`
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} runId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RunsViewerTokensList: async (runId: string, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('apiV1RunsViewerTokensList', 'runId', runId)
            const localVarPath = `/api/v1/runs/{run_id}/viewer-tokens/`
                .replace(`{${"run_id"}}`, encodeURIComponent(String(runId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SesMessagesList: async (limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ses/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionsDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/subscriptions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionsRead: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/subscriptions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserPartialUpdate: async (data: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1UserPartialUpdate', 'data', data)
            const localVarPath = `/api/v1/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserRead: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserUpdate: async (data: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1UserUpdate', 'data', data)
            const localVarPath = `/api/v1/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1UsersDelete', 'id', id)
            const localVarPath = `/api/v1/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersList: async (limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UserManage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersPartialUpdate: async (id: string, data: UserManage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1UsersPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1UsersPartialUpdate', 'data', data)
            const localVarPath = `/api/v1/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UserManage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUpdate: async (id: string, data: UserManage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1UsersUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiV1UsersUpdate', 'data', data)
            const localVarPath = `/api/v1/users/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiApi - functional programming interface
 * @export
 */
export const ApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiApiAxiosParamCreator(configuration)
    return {
        /**
         * Lease auto run for `AUTORUN_LEASED_TIME` minutes at the time
         * @param {string} id 
         * @param {AutoRunJobRead} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsJobsAck(id: string, data: AutoRunJobRead, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoRunJobRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsJobsAck(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AutoRunJobWrite} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsJobsCreate(data: AutoRunJobWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoRunJobRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsJobsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsJobsDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsJobsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lease leased auto run for `AUTORUN_LEASED_TIME` minutes at the time
         * @param {string} id 
         * @param {AutoRunJobRead} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsJobsExtendLease(id: string, data: AutoRunJobRead, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoRunJobRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsJobsExtendLease(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [isAvailable] is_available
         * @param {'W' | 'P' | 'C'} [status] status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsJobsList(isAvailable?: string, status?: 'W' | 'P' | 'C', limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsJobsList(isAvailable, status, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Make auto run available
         * @param {string} id 
         * @param {AutoRunJobRead} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsJobsNack(id: string, data: AutoRunJobRead, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoRunJobRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsJobsNack(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {AutoRunJobRead} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsJobsPartialUpdate(id: string, data: AutoRunJobRead, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoRunJobRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsJobsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsJobsRead(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoRunJobRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsJobsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {AutoRunJobWrite} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsJobsUpdate(id: string, data: AutoRunJobWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoRunJobRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsJobsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'' | 'S' | 'F'} [status] status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsList(status?: '' | 'S' | 'F', limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsList(status, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} checkId 
         * @param {CheckRetry} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsRetryCreate(id: string, checkId: string, data: CheckRetry, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckRetry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsRetryCreate(id, checkId, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return check retry list.
         * @param {string} id 
         * @param {string} checkId 
         * @param {'' | 'S' | 'F'} [status] status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsRetryRead(id: string, checkId: string, status?: '' | 'S' | 'F', limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsRetryRead(id, checkId, status, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AutoRunSchedule} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsScheduleCreate(data: AutoRunSchedule, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoRunSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsScheduleCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Auto Run Schedule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsScheduleDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsScheduleDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsScheduleList(limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsScheduleList(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Auto Run Schedule.
         * @param {AutoRunSchedule} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsSchedulePartialUpdate(id: string, data: AutoRunSchedule, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoRunSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsSchedulePartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Auto Run Schedule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsScheduleRead(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoRunSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsScheduleRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Auto Run Schedule.
         * @param {AutoRunSchedule} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsScheduleUpdate(id: string, data: AutoRunSchedule, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoRunSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsScheduleUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve autorun by viewer token
         * @param {string} token 
         * @param {'' | 'S' | 'F'} [status] status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsViewAutorun(token: string, status?: '' | 'S' | 'F', limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsViewAutorun(token, status, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} runId 
         * @param {ViewerToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsViewerTokensCreate(runId: string, data: ViewerToken, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewerToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsViewerTokensCreate(runId, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsViewerTokensDelete(runId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsViewerTokensDelete(runId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} runId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1AutoRunsViewerTokensList(runId: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1AutoRunsViewerTokensList(runId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SessionRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CheckoutCreate(data: SessionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CheckoutCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CheckoutList(limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CheckoutList(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Order} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CheckoutPartialUpdate(id: string, data: Order, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CheckoutPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Order} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CheckoutUpdate(id: string, data: Order, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CheckoutUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1DashboardRunsList(limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1DashboardRunsList(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} file 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FilesCreate(file: any, description?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FilesCreate(file, description, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAttachmentFromURL} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FilesCreateFromUrl(data: CreateAttachmentFromURL, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAttachmentFromURL>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FilesCreateFromUrl(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Attachment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FilesDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FilesDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Inquiry} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InquiryCreate(data: Inquiry, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Inquiry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InquiryCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Invitation} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InvitationsCreate(data: Invitation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invitation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InvitationsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InvitationsDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InvitationsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InvitationsList(limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InvitationsList(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InvitationsRead(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invitation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InvitationsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Invitation} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InvitationsResend(id: string, data: Invitation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invitation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InvitationsResend(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
         * @param {Login} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LoginCreate(data: Login, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Login>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LoginCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Accepts/Returns nothing.
         * @summary Calls Django logout method and delete the Token object assigned to the current User object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LogoutCreate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LogoutCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Accepts/Returns nothing.
         * @summary Calls Django logout method and delete the Token object assigned to the current User object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1LogoutList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1LogoutList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MeRead(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MeRead(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
         * @summary Calls Django Auth SetPasswordForm save method.
         * @param {PasswordChange} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PasswordChangeCreate(data: PasswordChange, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordChange>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PasswordChangeCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
         * @summary Password reset e-mail link is confirmed, therefore this resets the user\'s password.
         * @param {PasswordResetConfirm} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PasswordResetConfirmCreate(data: PasswordResetConfirm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordResetConfirm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PasswordResetConfirmCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Accepts the following POST parameters: email Returns the success/fail message.
         * @summary Calls Django Auth PasswordResetForm save method.
         * @param {PasswordReset} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PasswordResetCreate(data: PasswordReset, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordReset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PasswordResetCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {PlatformWrite} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PlatformsCreate(projectId: string, data: PlatformWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlatformRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PlatformsCreate(projectId, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PlatformsDelete(projectId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PlatformsDelete(projectId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PlatformsList(projectId: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20021>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PlatformsList(projectId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {PlatformWrite} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PlatformsPartialUpdate(projectId: string, id: string, data: PlatformWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlatformRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PlatformsPartialUpdate(projectId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PlatformsRead(projectId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlatformRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PlatformsRead(projectId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {PlatformWrite} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PlatformsUpdate(projectId: string, id: string, data: PlatformWrite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlatformRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PlatformsUpdate(projectId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProductsList(limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2009>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProductsList(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ApiKey} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsApiKeysCreate(projectId: string, data: ApiKey, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsApiKeysCreate(projectId, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsApiKeysDelete(projectId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsApiKeysDelete(projectId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsApiKeysList(projectId: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20011>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsApiKeysList(projectId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsAutoRunsChecksDelete(projectId: string, runId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsAutoRunsChecksDelete(projectId, runId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} [tags] tags
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsAutoRunsChecksList(projectId: string, runId: string, tags?: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsAutoRunsChecksList(projectId, runId, tags, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {Check} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsAutoRunsChecksPartialUpdate(projectId: string, runId: string, id: string, data: Check, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Check>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsAutoRunsChecksPartialUpdate(projectId, runId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsAutoRunsChecksRead(projectId: string, runId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Check>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsAutoRunsChecksRead(projectId, runId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {Check} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsAutoRunsChecksUpdate(projectId: string, runId: string, id: string, data: Check, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Check>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsAutoRunsChecksUpdate(projectId, runId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {InlineObject} data 
         * @param {Array<string>} [tags] 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsAutoRunsCreate(projectId: string, data: InlineObject, tags?: Array<string>, keyword?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoRun>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsAutoRunsCreate(projectId, data, tags, keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsAutoRunsDelete(projectId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsAutoRunsDelete(projectId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsAutoRunsList(projectId: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20012>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsAutoRunsList(projectId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {InlineObject2} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsAutoRunsPartialUpdate(projectId: string, id: string, data: InlineObject2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoRun>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsAutoRunsPartialUpdate(projectId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsAutoRunsRead(projectId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoRun>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsAutoRunsRead(projectId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create Flow and Cases for corresponded project, Copy Flow to snapshot/checks objects and add them to corresponded Run
         * @param {string} projectId 
         * @param {string} runId 
         * @param {Flow} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsAutoRunsSnapshotsCreate(projectId: string, runId: string, data: Flow, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Flow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsAutoRunsSnapshotsCreate(projectId, runId, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create Case for corresponded flow/project Copy the Case to new Check and add it to corresponded snapshot
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {FlowSnapshotRead} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsAutoRunsSnapshotsCreateCheck(projectId: string, runId: string, id: string, data: FlowSnapshotRead, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowSnapshotRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsAutoRunsSnapshotsCreateCheck(projectId, runId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {InlineObject3} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsAutoRunsSnapshotsPartialUpdate(projectId: string, runId: string, id: string, data: InlineObject3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowSnapshotRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsAutoRunsSnapshotsPartialUpdate(projectId, runId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsAutoRunsSnapshotsRead(projectId: string, runId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowSnapshotRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsAutoRunsSnapshotsRead(projectId, runId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {InlineObject1} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsAutoRunsUpdate(projectId: string, id: string, data: InlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoRun>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsAutoRunsUpdate(projectId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Project.
         * @param {Project} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsBookmarkCreate(id: number, data: Project, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsBookmarkCreate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsBookmarkDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsBookmarkDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Case} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsCasesCreate(projectId: string, data: Case, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Case>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsCasesCreate(projectId, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsCasesDelete(projectId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsCasesDelete(projectId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {string} [tags] tags
         * @param {string} [keyword] keyword
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsCasesHistory(projectId: string, id: string, tags?: string, keyword?: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsCasesHistory(projectId, id, tags, keyword, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {Case} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsCasesPartialUpdate(projectId: string, id: string, data: Case, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Case>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsCasesPartialUpdate(projectId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsCasesRead(projectId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Case>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsCasesRead(projectId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsCasesStatistics(projectId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsCasesStatistics(projectId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {Case} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsCasesUpdate(projectId: string, id: string, data: Case, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Case>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsCasesUpdate(projectId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Project} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsCreate(data: Project, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [tags] tags
         * @param {string} [keyword] keyword
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsFlowCasesList(projectId: string, tags?: string, keyword?: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20014>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsFlowCasesList(projectId, tags, keyword, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Flow} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsFlowsCreate(projectId: string, data: Flow, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Flow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsFlowsCreate(projectId, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsFlowsDelete(projectId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsFlowsDelete(projectId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsFlowsList(projectId: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20015>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsFlowsList(projectId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {Flow} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsFlowsPartialUpdate(projectId: string, id: string, data: Flow, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Flow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsFlowsPartialUpdate(projectId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsFlowsRead(projectId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Flow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsFlowsRead(projectId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {Flow} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsFlowsUpdate(projectId: string, id: string, data: Flow, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Flow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsFlowsUpdate(projectId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [isActive] is_active
         * @param {string} [keyword] keyword
         * @param {string} [bookmark] bookmark
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsList(isActive?: string, keyword?: string, bookmark?: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20010>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsList(isActive, keyword, bookmark, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Project.
         * @param {Project} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsPartialUpdate(id: string, data: Project, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRead(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Recipient} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRecipientsCreate(projectId: string, data: Recipient, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recipient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRecipientsCreate(projectId, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRecipientsDelete(projectId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRecipientsDelete(projectId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRecipientsList(projectId: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20016>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRecipientsList(projectId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {Recipient} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRecipientsPartialUpdate(projectId: string, id: string, data: Recipient, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recipient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRecipientsPartialUpdate(projectId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRecipientsRead(projectId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recipient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRecipientsRead(projectId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {Recipient} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRecipientsUpdate(projectId: string, id: string, data: Recipient, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recipient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRecipientsUpdate(projectId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} caseId 
         * @param {NamedBug} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsBugsCreate(projectId: string, caseId: string, data: NamedBug, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NamedBug>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsBugsCreate(projectId, caseId, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} caseId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsBugsDelete(projectId: string, caseId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsBugsDelete(projectId, caseId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} caseId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsBugsList(projectId: string, caseId: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20018>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsBugsList(projectId, caseId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} caseId 
         * @param {string} id 
         * @param {NamedBug} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsBugsPartialUpdate(projectId: string, caseId: string, id: string, data: NamedBug, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NamedBug>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsBugsPartialUpdate(projectId, caseId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} caseId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsBugsRead(projectId: string, caseId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NamedBug>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsBugsRead(projectId, caseId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} caseId 
         * @param {string} id 
         * @param {NamedBug} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsBugsUpdate(projectId: string, caseId: string, id: string, data: NamedBug, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NamedBug>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsBugsUpdate(projectId, caseId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsChecksDelete(projectId: string, runId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsChecksDelete(projectId, runId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} [tags] tags
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsChecksList(projectId: string, runId: string, tags?: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsChecksList(projectId, runId, tags, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {Check} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsChecksPartialUpdate(projectId: string, runId: string, id: string, data: Check, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Check>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsChecksPartialUpdate(projectId, runId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsChecksRead(projectId: string, runId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Check>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsChecksRead(projectId, runId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {Check} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsChecksUpdate(projectId: string, runId: string, id: string, data: Check, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Check>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsChecksUpdate(projectId, runId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {InlineObject4} data 
         * @param {Array<string>} [tags] 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsCreate(projectId: string, data: InlineObject4, tags?: Array<string>, keyword?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Run>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsCreate(projectId, data, tags, keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsCsv(projectId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunCSV>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsCsv(projectId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsDelete(projectId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsDelete(projectId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {'P' | 'S' | 'C'} [state] state
         * @param {string} [hasReports] has_reports
         * @param {string} [reportCreatedAt] report_created_at
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsList(projectId: string, state?: 'P' | 'S' | 'C', hasReports?: string, reportCreatedAt?: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20017>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsList(projectId, state, hasReports, reportCreatedAt, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {InlineObject6} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsPartialUpdate(projectId: string, id: string, data: InlineObject6, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Run>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsPartialUpdate(projectId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsRead(projectId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Run>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsRead(projectId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create Flow and Cases for corresponded project, Copy Flow to snapshot/checks objects and add them to corresponded Run
         * @param {string} projectId 
         * @param {string} runId 
         * @param {Flow} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsSnapshotsCreate(projectId: string, runId: string, data: Flow, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Flow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsSnapshotsCreate(projectId, runId, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create Case for corresponded flow/project Copy the Case to new Check and add it to corresponded snapshot
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {FlowSnapshotRead} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsSnapshotsCreateCheck(projectId: string, runId: string, id: string, data: FlowSnapshotRead, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowSnapshotRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsSnapshotsCreateCheck(projectId, runId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {InlineObject7} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsSnapshotsPartialUpdate(projectId: string, runId: string, id: string, data: InlineObject7, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowSnapshotRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsSnapshotsPartialUpdate(projectId, runId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsSnapshotsRead(projectId: string, runId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlowSnapshotRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsSnapshotsRead(projectId, runId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {InlineObject5} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsRunsUpdate(projectId: string, id: string, data: InlineObject5, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Run>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsRunsUpdate(projectId, id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsStatistics(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsStatistics(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Project.
         * @param {Project} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ProjectsUpdate(id: string, data: Project, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ProjectsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Register} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RegistrationCreate(data: Register, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Register>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RegistrationCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResendEmailVerification} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RegistrationResendEmailCreate(data: ResendEmailVerification, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResendEmailVerification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RegistrationResendEmailCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyEmail} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RegistrationVerifyEmailCreate(data: VerifyEmail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyEmail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RegistrationVerifyEmailCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve run by viewer token
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RunsViewRead(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RunsViewRead(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} runId 
         * @param {ViewerToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RunsViewerTokensCreate(runId: string, data: ViewerToken, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewerToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RunsViewerTokensCreate(runId, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RunsViewerTokensDelete(runId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RunsViewerTokensDelete(runId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} runId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RunsViewerTokensList(runId: string, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RunsViewerTokensList(runId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SesMessagesList(limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20019>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SesMessagesList(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SubscriptionsDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SubscriptionsDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SubscriptionsRead(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SubscriptionsRead(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserPartialUpdate(data: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserPartialUpdate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserRead(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserRead(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserUpdate(data: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserUpdate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersList(limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20020>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersList(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UserManage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersPartialUpdate(id: string, data: UserManage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserManage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UserManage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UsersUpdate(id: string, data: UserManage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserManage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UsersUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiApi - factory interface
 * @export
 */
export const ApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiApiFp(configuration)
    return {
        /**
         * Lease auto run for `AUTORUN_LEASED_TIME` minutes at the time
         * @param {string} id 
         * @param {AutoRunJobRead} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsJobsAck(id: string, data: AutoRunJobRead, options?: any): AxiosPromise<AutoRunJobRead> {
            return localVarFp.apiV1AutoRunsJobsAck(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AutoRunJobWrite} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsJobsCreate(data: AutoRunJobWrite, options?: any): AxiosPromise<AutoRunJobRead> {
            return localVarFp.apiV1AutoRunsJobsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsJobsDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AutoRunsJobsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Lease leased auto run for `AUTORUN_LEASED_TIME` minutes at the time
         * @param {string} id 
         * @param {AutoRunJobRead} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsJobsExtendLease(id: string, data: AutoRunJobRead, options?: any): AxiosPromise<AutoRunJobRead> {
            return localVarFp.apiV1AutoRunsJobsExtendLease(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [isAvailable] is_available
         * @param {'W' | 'P' | 'C'} [status] status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsJobsList(isAvailable?: string, status?: 'W' | 'P' | 'C', limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.apiV1AutoRunsJobsList(isAvailable, status, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Make auto run available
         * @param {string} id 
         * @param {AutoRunJobRead} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsJobsNack(id: string, data: AutoRunJobRead, options?: any): AxiosPromise<AutoRunJobRead> {
            return localVarFp.apiV1AutoRunsJobsNack(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {AutoRunJobRead} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsJobsPartialUpdate(id: string, data: AutoRunJobRead, options?: any): AxiosPromise<AutoRunJobRead> {
            return localVarFp.apiV1AutoRunsJobsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsJobsRead(id: string, options?: any): AxiosPromise<AutoRunJobRead> {
            return localVarFp.apiV1AutoRunsJobsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {AutoRunJobWrite} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsJobsUpdate(id: string, data: AutoRunJobWrite, options?: any): AxiosPromise<AutoRunJobRead> {
            return localVarFp.apiV1AutoRunsJobsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'' | 'S' | 'F'} [status] status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsList(status?: '' | 'S' | 'F', limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.apiV1AutoRunsList(status, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} checkId 
         * @param {CheckRetry} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsRetryCreate(id: string, checkId: string, data: CheckRetry, options?: any): AxiosPromise<CheckRetry> {
            return localVarFp.apiV1AutoRunsRetryCreate(id, checkId, data, options).then((request) => request(axios, basePath));
        },
        /**
         * Return check retry list.
         * @param {string} id 
         * @param {string} checkId 
         * @param {'' | 'S' | 'F'} [status] status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsRetryRead(id: string, checkId: string, status?: '' | 'S' | 'F', limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse2004> {
            return localVarFp.apiV1AutoRunsRetryRead(id, checkId, status, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AutoRunSchedule} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsScheduleCreate(data: AutoRunSchedule, options?: any): AxiosPromise<AutoRunSchedule> {
            return localVarFp.apiV1AutoRunsScheduleCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Auto Run Schedule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsScheduleDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AutoRunsScheduleDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsScheduleList(limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.apiV1AutoRunsScheduleList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Auto Run Schedule.
         * @param {AutoRunSchedule} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsSchedulePartialUpdate(id: string, data: AutoRunSchedule, options?: any): AxiosPromise<AutoRunSchedule> {
            return localVarFp.apiV1AutoRunsSchedulePartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Auto Run Schedule.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsScheduleRead(id: string, options?: any): AxiosPromise<AutoRunSchedule> {
            return localVarFp.apiV1AutoRunsScheduleRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Auto Run Schedule.
         * @param {AutoRunSchedule} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsScheduleUpdate(id: string, data: AutoRunSchedule, options?: any): AxiosPromise<AutoRunSchedule> {
            return localVarFp.apiV1AutoRunsScheduleUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve autorun by viewer token
         * @param {string} token 
         * @param {'' | 'S' | 'F'} [status] status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsViewAutorun(token: string, status?: '' | 'S' | 'F', limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.apiV1AutoRunsViewAutorun(token, status, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} runId 
         * @param {ViewerToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsViewerTokensCreate(runId: string, data: ViewerToken, options?: any): AxiosPromise<ViewerToken> {
            return localVarFp.apiV1AutoRunsViewerTokensCreate(runId, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsViewerTokensDelete(runId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1AutoRunsViewerTokensDelete(runId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} runId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1AutoRunsViewerTokensList(runId: string, limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse2005> {
            return localVarFp.apiV1AutoRunsViewerTokensList(runId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SessionRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CheckoutCreate(data: SessionRequest, options?: any): AxiosPromise<SessionRequest> {
            return localVarFp.apiV1CheckoutCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CheckoutList(limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse2006> {
            return localVarFp.apiV1CheckoutList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Order} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CheckoutPartialUpdate(id: string, data: Order, options?: any): AxiosPromise<Order> {
            return localVarFp.apiV1CheckoutPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Order} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CheckoutUpdate(id: string, data: Order, options?: any): AxiosPromise<Order> {
            return localVarFp.apiV1CheckoutUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1DashboardRunsList(limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.apiV1DashboardRunsList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} file 
         * @param {string} [description] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesCreate(file: any, description?: string, options?: any): AxiosPromise<Attachment> {
            return localVarFp.apiV1FilesCreate(file, description, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAttachmentFromURL} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesCreateFromUrl(data: CreateAttachmentFromURL, options?: any): AxiosPromise<CreateAttachmentFromURL> {
            return localVarFp.apiV1FilesCreateFromUrl(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this Attachment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FilesDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1FilesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Inquiry} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InquiryCreate(data: Inquiry, options?: any): AxiosPromise<Inquiry> {
            return localVarFp.apiV1InquiryCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Invitation} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitationsCreate(data: Invitation, options?: any): AxiosPromise<Invitation> {
            return localVarFp.apiV1InvitationsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitationsDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1InvitationsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitationsList(limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.apiV1InvitationsList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitationsRead(id: string, options?: any): AxiosPromise<Invitation> {
            return localVarFp.apiV1InvitationsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Invitation} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InvitationsResend(id: string, data: Invitation, options?: any): AxiosPromise<Invitation> {
            return localVarFp.apiV1InvitationsResend(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
         * @param {Login} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LoginCreate(data: Login, options?: any): AxiosPromise<Login> {
            return localVarFp.apiV1LoginCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * Accepts/Returns nothing.
         * @summary Calls Django logout method and delete the Token object assigned to the current User object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LogoutCreate(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1LogoutCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * Accepts/Returns nothing.
         * @summary Calls Django logout method and delete the Token object assigned to the current User object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1LogoutList(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1LogoutList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MeRead(options?: any): AxiosPromise<ApiKey> {
            return localVarFp.apiV1MeRead(options).then((request) => request(axios, basePath));
        },
        /**
         * Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
         * @summary Calls Django Auth SetPasswordForm save method.
         * @param {PasswordChange} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PasswordChangeCreate(data: PasswordChange, options?: any): AxiosPromise<PasswordChange> {
            return localVarFp.apiV1PasswordChangeCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
         * @summary Password reset e-mail link is confirmed, therefore this resets the user\'s password.
         * @param {PasswordResetConfirm} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PasswordResetConfirmCreate(data: PasswordResetConfirm, options?: any): AxiosPromise<PasswordResetConfirm> {
            return localVarFp.apiV1PasswordResetConfirmCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * Accepts the following POST parameters: email Returns the success/fail message.
         * @summary Calls Django Auth PasswordResetForm save method.
         * @param {PasswordReset} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PasswordResetCreate(data: PasswordReset, options?: any): AxiosPromise<PasswordReset> {
            return localVarFp.apiV1PasswordResetCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {PlatformWrite} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PlatformsCreate(projectId: string, data: PlatformWrite, options?: any): AxiosPromise<PlatformRead> {
            return localVarFp.apiV1PlatformsCreate(projectId, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PlatformsDelete(projectId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1PlatformsDelete(projectId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PlatformsList(projectId: string, limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse20021> {
            return localVarFp.apiV1PlatformsList(projectId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {PlatformWrite} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PlatformsPartialUpdate(projectId: string, id: string, data: PlatformWrite, options?: any): AxiosPromise<PlatformRead> {
            return localVarFp.apiV1PlatformsPartialUpdate(projectId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PlatformsRead(projectId: string, id: string, options?: any): AxiosPromise<PlatformRead> {
            return localVarFp.apiV1PlatformsRead(projectId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {PlatformWrite} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PlatformsUpdate(projectId: string, id: string, data: PlatformWrite, options?: any): AxiosPromise<PlatformRead> {
            return localVarFp.apiV1PlatformsUpdate(projectId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProductsList(limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse2009> {
            return localVarFp.apiV1ProductsList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {ApiKey} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsApiKeysCreate(projectId: string, data: ApiKey, options?: any): AxiosPromise<ApiKey> {
            return localVarFp.apiV1ProjectsApiKeysCreate(projectId, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsApiKeysDelete(projectId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ProjectsApiKeysDelete(projectId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsApiKeysList(projectId: string, limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse20011> {
            return localVarFp.apiV1ProjectsApiKeysList(projectId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsChecksDelete(projectId: string, runId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ProjectsAutoRunsChecksDelete(projectId, runId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} [tags] tags
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsChecksList(projectId: string, runId: string, tags?: string, limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse20013> {
            return localVarFp.apiV1ProjectsAutoRunsChecksList(projectId, runId, tags, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {Check} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsChecksPartialUpdate(projectId: string, runId: string, id: string, data: Check, options?: any): AxiosPromise<Check> {
            return localVarFp.apiV1ProjectsAutoRunsChecksPartialUpdate(projectId, runId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsChecksRead(projectId: string, runId: string, id: string, options?: any): AxiosPromise<Check> {
            return localVarFp.apiV1ProjectsAutoRunsChecksRead(projectId, runId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {Check} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsChecksUpdate(projectId: string, runId: string, id: string, data: Check, options?: any): AxiosPromise<Check> {
            return localVarFp.apiV1ProjectsAutoRunsChecksUpdate(projectId, runId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {InlineObject} data 
         * @param {Array<string>} [tags] 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsCreate(projectId: string, data: InlineObject, tags?: Array<string>, keyword?: string, options?: any): AxiosPromise<AutoRun> {
            return localVarFp.apiV1ProjectsAutoRunsCreate(projectId, data, tags, keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsDelete(projectId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ProjectsAutoRunsDelete(projectId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsList(projectId: string, limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse20012> {
            return localVarFp.apiV1ProjectsAutoRunsList(projectId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {InlineObject2} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsPartialUpdate(projectId: string, id: string, data: InlineObject2, options?: any): AxiosPromise<AutoRun> {
            return localVarFp.apiV1ProjectsAutoRunsPartialUpdate(projectId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsRead(projectId: string, id: string, options?: any): AxiosPromise<AutoRun> {
            return localVarFp.apiV1ProjectsAutoRunsRead(projectId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Create Flow and Cases for corresponded project, Copy Flow to snapshot/checks objects and add them to corresponded Run
         * @param {string} projectId 
         * @param {string} runId 
         * @param {Flow} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsSnapshotsCreate(projectId: string, runId: string, data: Flow, options?: any): AxiosPromise<Flow> {
            return localVarFp.apiV1ProjectsAutoRunsSnapshotsCreate(projectId, runId, data, options).then((request) => request(axios, basePath));
        },
        /**
         * Create Case for corresponded flow/project Copy the Case to new Check and add it to corresponded snapshot
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {FlowSnapshotRead} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsSnapshotsCreateCheck(projectId: string, runId: string, id: string, data: FlowSnapshotRead, options?: any): AxiosPromise<FlowSnapshotRead> {
            return localVarFp.apiV1ProjectsAutoRunsSnapshotsCreateCheck(projectId, runId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {InlineObject3} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsSnapshotsPartialUpdate(projectId: string, runId: string, id: string, data: InlineObject3, options?: any): AxiosPromise<FlowSnapshotRead> {
            return localVarFp.apiV1ProjectsAutoRunsSnapshotsPartialUpdate(projectId, runId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsSnapshotsRead(projectId: string, runId: string, id: string, options?: any): AxiosPromise<FlowSnapshotRead> {
            return localVarFp.apiV1ProjectsAutoRunsSnapshotsRead(projectId, runId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {InlineObject1} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsAutoRunsUpdate(projectId: string, id: string, data: InlineObject1, options?: any): AxiosPromise<AutoRun> {
            return localVarFp.apiV1ProjectsAutoRunsUpdate(projectId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Project.
         * @param {Project} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsBookmarkCreate(id: number, data: Project, options?: any): AxiosPromise<Project> {
            return localVarFp.apiV1ProjectsBookmarkCreate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsBookmarkDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ProjectsBookmarkDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Case} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsCasesCreate(projectId: string, data: Case, options?: any): AxiosPromise<Case> {
            return localVarFp.apiV1ProjectsCasesCreate(projectId, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsCasesDelete(projectId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ProjectsCasesDelete(projectId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {string} [tags] tags
         * @param {string} [keyword] keyword
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsCasesHistory(projectId: string, id: string, tags?: string, keyword?: string, limit?: number, offset?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ProjectsCasesHistory(projectId, id, tags, keyword, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {Case} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsCasesPartialUpdate(projectId: string, id: string, data: Case, options?: any): AxiosPromise<Case> {
            return localVarFp.apiV1ProjectsCasesPartialUpdate(projectId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsCasesRead(projectId: string, id: string, options?: any): AxiosPromise<Case> {
            return localVarFp.apiV1ProjectsCasesRead(projectId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsCasesStatistics(projectId: string, id: string, options?: any): AxiosPromise<CaseStats> {
            return localVarFp.apiV1ProjectsCasesStatistics(projectId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {Case} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsCasesUpdate(projectId: string, id: string, data: Case, options?: any): AxiosPromise<Case> {
            return localVarFp.apiV1ProjectsCasesUpdate(projectId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Project} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsCreate(data: Project, options?: any): AxiosPromise<Project> {
            return localVarFp.apiV1ProjectsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ProjectsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [tags] tags
         * @param {string} [keyword] keyword
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsFlowCasesList(projectId: string, tags?: string, keyword?: string, limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse20014> {
            return localVarFp.apiV1ProjectsFlowCasesList(projectId, tags, keyword, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Flow} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsFlowsCreate(projectId: string, data: Flow, options?: any): AxiosPromise<Flow> {
            return localVarFp.apiV1ProjectsFlowsCreate(projectId, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsFlowsDelete(projectId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ProjectsFlowsDelete(projectId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsFlowsList(projectId: string, limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse20015> {
            return localVarFp.apiV1ProjectsFlowsList(projectId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {Flow} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsFlowsPartialUpdate(projectId: string, id: string, data: Flow, options?: any): AxiosPromise<Flow> {
            return localVarFp.apiV1ProjectsFlowsPartialUpdate(projectId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsFlowsRead(projectId: string, id: string, options?: any): AxiosPromise<Flow> {
            return localVarFp.apiV1ProjectsFlowsRead(projectId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {Flow} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsFlowsUpdate(projectId: string, id: string, data: Flow, options?: any): AxiosPromise<Flow> {
            return localVarFp.apiV1ProjectsFlowsUpdate(projectId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [isActive] is_active
         * @param {string} [keyword] keyword
         * @param {string} [bookmark] bookmark
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsList(isActive?: string, keyword?: string, bookmark?: string, limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse20010> {
            return localVarFp.apiV1ProjectsList(isActive, keyword, bookmark, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Project.
         * @param {Project} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsPartialUpdate(id: string, data: Project, options?: any): AxiosPromise<Project> {
            return localVarFp.apiV1ProjectsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRead(id: string, options?: any): AxiosPromise<Project> {
            return localVarFp.apiV1ProjectsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {Recipient} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRecipientsCreate(projectId: string, data: Recipient, options?: any): AxiosPromise<Recipient> {
            return localVarFp.apiV1ProjectsRecipientsCreate(projectId, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRecipientsDelete(projectId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ProjectsRecipientsDelete(projectId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRecipientsList(projectId: string, limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse20016> {
            return localVarFp.apiV1ProjectsRecipientsList(projectId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {Recipient} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRecipientsPartialUpdate(projectId: string, id: string, data: Recipient, options?: any): AxiosPromise<Recipient> {
            return localVarFp.apiV1ProjectsRecipientsPartialUpdate(projectId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRecipientsRead(projectId: string, id: string, options?: any): AxiosPromise<Recipient> {
            return localVarFp.apiV1ProjectsRecipientsRead(projectId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {Recipient} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRecipientsUpdate(projectId: string, id: string, data: Recipient, options?: any): AxiosPromise<Recipient> {
            return localVarFp.apiV1ProjectsRecipientsUpdate(projectId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} caseId 
         * @param {NamedBug} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsBugsCreate(projectId: string, caseId: string, data: NamedBug, options?: any): AxiosPromise<NamedBug> {
            return localVarFp.apiV1ProjectsRunsBugsCreate(projectId, caseId, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} caseId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsBugsDelete(projectId: string, caseId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ProjectsRunsBugsDelete(projectId, caseId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} caseId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsBugsList(projectId: string, caseId: string, limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse20018> {
            return localVarFp.apiV1ProjectsRunsBugsList(projectId, caseId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} caseId 
         * @param {string} id 
         * @param {NamedBug} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsBugsPartialUpdate(projectId: string, caseId: string, id: string, data: NamedBug, options?: any): AxiosPromise<NamedBug> {
            return localVarFp.apiV1ProjectsRunsBugsPartialUpdate(projectId, caseId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} caseId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsBugsRead(projectId: string, caseId: string, id: string, options?: any): AxiosPromise<NamedBug> {
            return localVarFp.apiV1ProjectsRunsBugsRead(projectId, caseId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} caseId 
         * @param {string} id 
         * @param {NamedBug} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsBugsUpdate(projectId: string, caseId: string, id: string, data: NamedBug, options?: any): AxiosPromise<NamedBug> {
            return localVarFp.apiV1ProjectsRunsBugsUpdate(projectId, caseId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsChecksDelete(projectId: string, runId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ProjectsRunsChecksDelete(projectId, runId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} [tags] tags
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsChecksList(projectId: string, runId: string, tags?: string, limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse20013> {
            return localVarFp.apiV1ProjectsRunsChecksList(projectId, runId, tags, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {Check} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsChecksPartialUpdate(projectId: string, runId: string, id: string, data: Check, options?: any): AxiosPromise<Check> {
            return localVarFp.apiV1ProjectsRunsChecksPartialUpdate(projectId, runId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsChecksRead(projectId: string, runId: string, id: string, options?: any): AxiosPromise<Check> {
            return localVarFp.apiV1ProjectsRunsChecksRead(projectId, runId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {Check} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsChecksUpdate(projectId: string, runId: string, id: string, data: Check, options?: any): AxiosPromise<Check> {
            return localVarFp.apiV1ProjectsRunsChecksUpdate(projectId, runId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {InlineObject4} data 
         * @param {Array<string>} [tags] 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsCreate(projectId: string, data: InlineObject4, tags?: Array<string>, keyword?: string, options?: any): AxiosPromise<Run> {
            return localVarFp.apiV1ProjectsRunsCreate(projectId, data, tags, keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsCsv(projectId: string, id: string, options?: any): AxiosPromise<RunCSV> {
            return localVarFp.apiV1ProjectsRunsCsv(projectId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsDelete(projectId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ProjectsRunsDelete(projectId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {'P' | 'S' | 'C'} [state] state
         * @param {string} [hasReports] has_reports
         * @param {string} [reportCreatedAt] report_created_at
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsList(projectId: string, state?: 'P' | 'S' | 'C', hasReports?: string, reportCreatedAt?: string, limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse20017> {
            return localVarFp.apiV1ProjectsRunsList(projectId, state, hasReports, reportCreatedAt, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {InlineObject6} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsPartialUpdate(projectId: string, id: string, data: InlineObject6, options?: any): AxiosPromise<Run> {
            return localVarFp.apiV1ProjectsRunsPartialUpdate(projectId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsRead(projectId: string, id: string, options?: any): AxiosPromise<Run> {
            return localVarFp.apiV1ProjectsRunsRead(projectId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Create Flow and Cases for corresponded project, Copy Flow to snapshot/checks objects and add them to corresponded Run
         * @param {string} projectId 
         * @param {string} runId 
         * @param {Flow} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsSnapshotsCreate(projectId: string, runId: string, data: Flow, options?: any): AxiosPromise<Flow> {
            return localVarFp.apiV1ProjectsRunsSnapshotsCreate(projectId, runId, data, options).then((request) => request(axios, basePath));
        },
        /**
         * Create Case for corresponded flow/project Copy the Case to new Check and add it to corresponded snapshot
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {FlowSnapshotRead} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsSnapshotsCreateCheck(projectId: string, runId: string, id: string, data: FlowSnapshotRead, options?: any): AxiosPromise<FlowSnapshotRead> {
            return localVarFp.apiV1ProjectsRunsSnapshotsCreateCheck(projectId, runId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {InlineObject7} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsSnapshotsPartialUpdate(projectId: string, runId: string, id: string, data: InlineObject7, options?: any): AxiosPromise<FlowSnapshotRead> {
            return localVarFp.apiV1ProjectsRunsSnapshotsPartialUpdate(projectId, runId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsSnapshotsRead(projectId: string, runId: string, id: string, options?: any): AxiosPromise<FlowSnapshotRead> {
            return localVarFp.apiV1ProjectsRunsSnapshotsRead(projectId, runId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} id 
         * @param {InlineObject5} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsRunsUpdate(projectId: string, id: string, data: InlineObject5, options?: any): AxiosPromise<Run> {
            return localVarFp.apiV1ProjectsRunsUpdate(projectId, id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsStatistics(id: string, options?: any): AxiosPromise<RunStats> {
            return localVarFp.apiV1ProjectsStatistics(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A unique integer value identifying this Project.
         * @param {Project} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ProjectsUpdate(id: string, data: Project, options?: any): AxiosPromise<Project> {
            return localVarFp.apiV1ProjectsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Register} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RegistrationCreate(data: Register, options?: any): AxiosPromise<Register> {
            return localVarFp.apiV1RegistrationCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResendEmailVerification} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RegistrationResendEmailCreate(data: ResendEmailVerification, options?: any): AxiosPromise<ResendEmailVerification> {
            return localVarFp.apiV1RegistrationResendEmailCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyEmail} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RegistrationVerifyEmailCreate(data: VerifyEmail, options?: any): AxiosPromise<VerifyEmail> {
            return localVarFp.apiV1RegistrationVerifyEmailCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve run by viewer token
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RunsViewRead(token: string, options?: any): AxiosPromise<RunView> {
            return localVarFp.apiV1RunsViewRead(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} runId 
         * @param {ViewerToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RunsViewerTokensCreate(runId: string, data: ViewerToken, options?: any): AxiosPromise<ViewerToken> {
            return localVarFp.apiV1RunsViewerTokensCreate(runId, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} runId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RunsViewerTokensDelete(runId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1RunsViewerTokensDelete(runId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} runId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RunsViewerTokensList(runId: string, limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse2005> {
            return localVarFp.apiV1RunsViewerTokensList(runId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SesMessagesList(limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse20019> {
            return localVarFp.apiV1SesMessagesList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionsDelete(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SubscriptionsDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionsRead(options?: any): AxiosPromise<Subscription> {
            return localVarFp.apiV1SubscriptionsRead(options).then((request) => request(axios, basePath));
        },
        /**
         * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserPartialUpdate(data: User, options?: any): AxiosPromise<User> {
            return localVarFp.apiV1UserPartialUpdate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserRead(options?: any): AxiosPromise<User> {
            return localVarFp.apiV1UserRead(options).then((request) => request(axios, basePath));
        },
        /**
         * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserUpdate(data: User, options?: any): AxiosPromise<User> {
            return localVarFp.apiV1UserUpdate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UsersDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersList(limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse20020> {
            return localVarFp.apiV1UsersList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UserManage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersPartialUpdate(id: string, data: UserManage, options?: any): AxiosPromise<UserManage> {
            return localVarFp.apiV1UsersPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UserManage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UsersUpdate(id: string, data: UserManage, options?: any): AxiosPromise<UserManage> {
            return localVarFp.apiV1UsersUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiApi - object-oriented interface
 * @export
 * @class ApiApi
 * @extends {BaseAPI}
 */
export class ApiApi extends BaseAPI {
    /**
     * Lease auto run for `AUTORUN_LEASED_TIME` minutes at the time
     * @param {string} id 
     * @param {AutoRunJobRead} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsJobsAck(id: string, data: AutoRunJobRead, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsJobsAck(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AutoRunJobWrite} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsJobsCreate(data: AutoRunJobWrite, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsJobsCreate(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsJobsDelete(id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsJobsDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lease leased auto run for `AUTORUN_LEASED_TIME` minutes at the time
     * @param {string} id 
     * @param {AutoRunJobRead} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsJobsExtendLease(id: string, data: AutoRunJobRead, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsJobsExtendLease(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [isAvailable] is_available
     * @param {'W' | 'P' | 'C'} [status] status
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsJobsList(isAvailable?: string, status?: 'W' | 'P' | 'C', limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsJobsList(isAvailable, status, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Make auto run available
     * @param {string} id 
     * @param {AutoRunJobRead} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsJobsNack(id: string, data: AutoRunJobRead, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsJobsNack(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {AutoRunJobRead} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsJobsPartialUpdate(id: string, data: AutoRunJobRead, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsJobsPartialUpdate(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsJobsRead(id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsJobsRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {AutoRunJobWrite} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsJobsUpdate(id: string, data: AutoRunJobWrite, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsJobsUpdate(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'' | 'S' | 'F'} [status] status
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsList(status?: '' | 'S' | 'F', limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsList(status, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} checkId 
     * @param {CheckRetry} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsRetryCreate(id: string, checkId: string, data: CheckRetry, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsRetryCreate(id, checkId, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return check retry list.
     * @param {string} id 
     * @param {string} checkId 
     * @param {'' | 'S' | 'F'} [status] status
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsRetryRead(id: string, checkId: string, status?: '' | 'S' | 'F', limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsRetryRead(id, checkId, status, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AutoRunSchedule} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsScheduleCreate(data: AutoRunSchedule, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsScheduleCreate(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A unique integer value identifying this Auto Run Schedule.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsScheduleDelete(id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsScheduleDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsScheduleList(limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsScheduleList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A unique integer value identifying this Auto Run Schedule.
     * @param {AutoRunSchedule} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsSchedulePartialUpdate(id: string, data: AutoRunSchedule, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsSchedulePartialUpdate(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A unique integer value identifying this Auto Run Schedule.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsScheduleRead(id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsScheduleRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A unique integer value identifying this Auto Run Schedule.
     * @param {AutoRunSchedule} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsScheduleUpdate(id: string, data: AutoRunSchedule, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsScheduleUpdate(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve autorun by viewer token
     * @param {string} token 
     * @param {'' | 'S' | 'F'} [status] status
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsViewAutorun(token: string, status?: '' | 'S' | 'F', limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsViewAutorun(token, status, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} runId 
     * @param {ViewerToken} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsViewerTokensCreate(runId: string, data: ViewerToken, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsViewerTokensCreate(runId, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} runId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsViewerTokensDelete(runId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsViewerTokensDelete(runId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} runId 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1AutoRunsViewerTokensList(runId: string, limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1AutoRunsViewerTokensList(runId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SessionRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1CheckoutCreate(data: SessionRequest, options?: any) {
        return ApiApiFp(this.configuration).apiV1CheckoutCreate(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1CheckoutList(limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1CheckoutList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Order} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1CheckoutPartialUpdate(id: string, data: Order, options?: any) {
        return ApiApiFp(this.configuration).apiV1CheckoutPartialUpdate(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Order} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1CheckoutUpdate(id: string, data: Order, options?: any) {
        return ApiApiFp(this.configuration).apiV1CheckoutUpdate(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1DashboardRunsList(limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1DashboardRunsList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} file 
     * @param {string} [description] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1FilesCreate(file: any, description?: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1FilesCreate(file, description, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAttachmentFromURL} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1FilesCreateFromUrl(data: CreateAttachmentFromURL, options?: any) {
        return ApiApiFp(this.configuration).apiV1FilesCreateFromUrl(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this Attachment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1FilesDelete(id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1FilesDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Inquiry} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1InquiryCreate(data: Inquiry, options?: any) {
        return ApiApiFp(this.configuration).apiV1InquiryCreate(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Invitation} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1InvitationsCreate(data: Invitation, options?: any) {
        return ApiApiFp(this.configuration).apiV1InvitationsCreate(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1InvitationsDelete(id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1InvitationsDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1InvitationsList(limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1InvitationsList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1InvitationsRead(id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1InvitationsRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Invitation} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1InvitationsResend(id: string, data: Invitation, options?: any) {
        return ApiApiFp(this.configuration).apiV1InvitationsResend(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
     * @param {Login} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1LoginCreate(data: Login, options?: any) {
        return ApiApiFp(this.configuration).apiV1LoginCreate(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Accepts/Returns nothing.
     * @summary Calls Django logout method and delete the Token object assigned to the current User object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1LogoutCreate(options?: any) {
        return ApiApiFp(this.configuration).apiV1LogoutCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Accepts/Returns nothing.
     * @summary Calls Django logout method and delete the Token object assigned to the current User object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1LogoutList(options?: any) {
        return ApiApiFp(this.configuration).apiV1LogoutList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1MeRead(options?: any) {
        return ApiApiFp(this.configuration).apiV1MeRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     * @summary Calls Django Auth SetPasswordForm save method.
     * @param {PasswordChange} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1PasswordChangeCreate(data: PasswordChange, options?: any) {
        return ApiApiFp(this.configuration).apiV1PasswordChangeCreate(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
     * @summary Password reset e-mail link is confirmed, therefore this resets the user\'s password.
     * @param {PasswordResetConfirm} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1PasswordResetConfirmCreate(data: PasswordResetConfirm, options?: any) {
        return ApiApiFp(this.configuration).apiV1PasswordResetConfirmCreate(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Accepts the following POST parameters: email Returns the success/fail message.
     * @summary Calls Django Auth PasswordResetForm save method.
     * @param {PasswordReset} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1PasswordResetCreate(data: PasswordReset, options?: any) {
        return ApiApiFp(this.configuration).apiV1PasswordResetCreate(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {PlatformWrite} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1PlatformsCreate(projectId: string, data: PlatformWrite, options?: any) {
        return ApiApiFp(this.configuration).apiV1PlatformsCreate(projectId, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1PlatformsDelete(projectId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1PlatformsDelete(projectId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1PlatformsList(projectId: string, limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1PlatformsList(projectId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {PlatformWrite} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1PlatformsPartialUpdate(projectId: string, id: string, data: PlatformWrite, options?: any) {
        return ApiApiFp(this.configuration).apiV1PlatformsPartialUpdate(projectId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1PlatformsRead(projectId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1PlatformsRead(projectId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {PlatformWrite} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1PlatformsUpdate(projectId: string, id: string, data: PlatformWrite, options?: any) {
        return ApiApiFp(this.configuration).apiV1PlatformsUpdate(projectId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProductsList(limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProductsList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {ApiKey} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsApiKeysCreate(projectId: string, data: ApiKey, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsApiKeysCreate(projectId, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsApiKeysDelete(projectId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsApiKeysDelete(projectId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsApiKeysList(projectId: string, limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsApiKeysList(projectId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} runId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsAutoRunsChecksDelete(projectId: string, runId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsAutoRunsChecksDelete(projectId, runId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} runId 
     * @param {string} [tags] tags
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsAutoRunsChecksList(projectId: string, runId: string, tags?: string, limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsAutoRunsChecksList(projectId, runId, tags, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} runId 
     * @param {string} id 
     * @param {Check} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsAutoRunsChecksPartialUpdate(projectId: string, runId: string, id: string, data: Check, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsAutoRunsChecksPartialUpdate(projectId, runId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} runId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsAutoRunsChecksRead(projectId: string, runId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsAutoRunsChecksRead(projectId, runId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} runId 
     * @param {string} id 
     * @param {Check} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsAutoRunsChecksUpdate(projectId: string, runId: string, id: string, data: Check, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsAutoRunsChecksUpdate(projectId, runId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {InlineObject} data 
     * @param {Array<string>} [tags] 
     * @param {string} [keyword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsAutoRunsCreate(projectId: string, data: InlineObject, tags?: Array<string>, keyword?: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsAutoRunsCreate(projectId, data, tags, keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsAutoRunsDelete(projectId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsAutoRunsDelete(projectId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsAutoRunsList(projectId: string, limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsAutoRunsList(projectId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {InlineObject2} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsAutoRunsPartialUpdate(projectId: string, id: string, data: InlineObject2, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsAutoRunsPartialUpdate(projectId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsAutoRunsRead(projectId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsAutoRunsRead(projectId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create Flow and Cases for corresponded project, Copy Flow to snapshot/checks objects and add them to corresponded Run
     * @param {string} projectId 
     * @param {string} runId 
     * @param {Flow} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsAutoRunsSnapshotsCreate(projectId: string, runId: string, data: Flow, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsAutoRunsSnapshotsCreate(projectId, runId, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create Case for corresponded flow/project Copy the Case to new Check and add it to corresponded snapshot
     * @param {string} projectId 
     * @param {string} runId 
     * @param {string} id 
     * @param {FlowSnapshotRead} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsAutoRunsSnapshotsCreateCheck(projectId: string, runId: string, id: string, data: FlowSnapshotRead, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsAutoRunsSnapshotsCreateCheck(projectId, runId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} runId 
     * @param {string} id 
     * @param {InlineObject3} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsAutoRunsSnapshotsPartialUpdate(projectId: string, runId: string, id: string, data: InlineObject3, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsAutoRunsSnapshotsPartialUpdate(projectId, runId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} runId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsAutoRunsSnapshotsRead(projectId: string, runId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsAutoRunsSnapshotsRead(projectId, runId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {InlineObject1} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsAutoRunsUpdate(projectId: string, id: string, data: InlineObject1, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsAutoRunsUpdate(projectId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this Project.
     * @param {Project} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsBookmarkCreate(id: number, data: Project, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsBookmarkCreate(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this Project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsBookmarkDelete(id: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsBookmarkDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {Case} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsCasesCreate(projectId: string, data: Case, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsCasesCreate(projectId, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsCasesDelete(projectId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsCasesDelete(projectId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {string} [tags] tags
     * @param {string} [keyword] keyword
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsCasesHistory(projectId: string, id: string, tags?: string, keyword?: string, limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsCasesHistory(projectId, id, tags, keyword, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {Case} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsCasesPartialUpdate(projectId: string, id: string, data: Case, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsCasesPartialUpdate(projectId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsCasesRead(projectId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsCasesRead(projectId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsCasesStatistics(projectId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsCasesStatistics(projectId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {Case} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsCasesUpdate(projectId: string, id: string, data: Case, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsCasesUpdate(projectId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Project} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsCreate(data: Project, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsCreate(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A unique integer value identifying this Project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsDelete(id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} [tags] tags
     * @param {string} [keyword] keyword
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsFlowCasesList(projectId: string, tags?: string, keyword?: string, limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsFlowCasesList(projectId, tags, keyword, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {Flow} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsFlowsCreate(projectId: string, data: Flow, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsFlowsCreate(projectId, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsFlowsDelete(projectId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsFlowsDelete(projectId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsFlowsList(projectId: string, limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsFlowsList(projectId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {Flow} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsFlowsPartialUpdate(projectId: string, id: string, data: Flow, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsFlowsPartialUpdate(projectId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsFlowsRead(projectId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsFlowsRead(projectId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {Flow} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsFlowsUpdate(projectId: string, id: string, data: Flow, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsFlowsUpdate(projectId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [isActive] is_active
     * @param {string} [keyword] keyword
     * @param {string} [bookmark] bookmark
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsList(isActive?: string, keyword?: string, bookmark?: string, limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsList(isActive, keyword, bookmark, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A unique integer value identifying this Project.
     * @param {Project} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsPartialUpdate(id: string, data: Project, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsPartialUpdate(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A unique integer value identifying this Project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRead(id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {Recipient} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRecipientsCreate(projectId: string, data: Recipient, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRecipientsCreate(projectId, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRecipientsDelete(projectId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRecipientsDelete(projectId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRecipientsList(projectId: string, limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRecipientsList(projectId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {Recipient} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRecipientsPartialUpdate(projectId: string, id: string, data: Recipient, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRecipientsPartialUpdate(projectId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRecipientsRead(projectId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRecipientsRead(projectId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {Recipient} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRecipientsUpdate(projectId: string, id: string, data: Recipient, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRecipientsUpdate(projectId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} caseId 
     * @param {NamedBug} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsBugsCreate(projectId: string, caseId: string, data: NamedBug, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsBugsCreate(projectId, caseId, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} caseId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsBugsDelete(projectId: string, caseId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsBugsDelete(projectId, caseId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} caseId 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsBugsList(projectId: string, caseId: string, limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsBugsList(projectId, caseId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} caseId 
     * @param {string} id 
     * @param {NamedBug} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsBugsPartialUpdate(projectId: string, caseId: string, id: string, data: NamedBug, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsBugsPartialUpdate(projectId, caseId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} caseId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsBugsRead(projectId: string, caseId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsBugsRead(projectId, caseId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} caseId 
     * @param {string} id 
     * @param {NamedBug} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsBugsUpdate(projectId: string, caseId: string, id: string, data: NamedBug, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsBugsUpdate(projectId, caseId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} runId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsChecksDelete(projectId: string, runId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsChecksDelete(projectId, runId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} runId 
     * @param {string} [tags] tags
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsChecksList(projectId: string, runId: string, tags?: string, limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsChecksList(projectId, runId, tags, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} runId 
     * @param {string} id 
     * @param {Check} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsChecksPartialUpdate(projectId: string, runId: string, id: string, data: Check, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsChecksPartialUpdate(projectId, runId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} runId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsChecksRead(projectId: string, runId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsChecksRead(projectId, runId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} runId 
     * @param {string} id 
     * @param {Check} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsChecksUpdate(projectId: string, runId: string, id: string, data: Check, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsChecksUpdate(projectId, runId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {InlineObject4} data 
     * @param {Array<string>} [tags] 
     * @param {string} [keyword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsCreate(projectId: string, data: InlineObject4, tags?: Array<string>, keyword?: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsCreate(projectId, data, tags, keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsCsv(projectId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsCsv(projectId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsDelete(projectId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsDelete(projectId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {'P' | 'S' | 'C'} [state] state
     * @param {string} [hasReports] has_reports
     * @param {string} [reportCreatedAt] report_created_at
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsList(projectId: string, state?: 'P' | 'S' | 'C', hasReports?: string, reportCreatedAt?: string, limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsList(projectId, state, hasReports, reportCreatedAt, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {InlineObject6} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsPartialUpdate(projectId: string, id: string, data: InlineObject6, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsPartialUpdate(projectId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsRead(projectId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsRead(projectId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create Flow and Cases for corresponded project, Copy Flow to snapshot/checks objects and add them to corresponded Run
     * @param {string} projectId 
     * @param {string} runId 
     * @param {Flow} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsSnapshotsCreate(projectId: string, runId: string, data: Flow, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsSnapshotsCreate(projectId, runId, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create Case for corresponded flow/project Copy the Case to new Check and add it to corresponded snapshot
     * @param {string} projectId 
     * @param {string} runId 
     * @param {string} id 
     * @param {FlowSnapshotRead} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsSnapshotsCreateCheck(projectId: string, runId: string, id: string, data: FlowSnapshotRead, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsSnapshotsCreateCheck(projectId, runId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} runId 
     * @param {string} id 
     * @param {InlineObject7} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsSnapshotsPartialUpdate(projectId: string, runId: string, id: string, data: InlineObject7, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsSnapshotsPartialUpdate(projectId, runId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} runId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsSnapshotsRead(projectId: string, runId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsSnapshotsRead(projectId, runId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} id 
     * @param {InlineObject5} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsRunsUpdate(projectId: string, id: string, data: InlineObject5, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsRunsUpdate(projectId, id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A unique integer value identifying this Project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsStatistics(id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsStatistics(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A unique integer value identifying this Project.
     * @param {Project} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1ProjectsUpdate(id: string, data: Project, options?: any) {
        return ApiApiFp(this.configuration).apiV1ProjectsUpdate(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Register} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1RegistrationCreate(data: Register, options?: any) {
        return ApiApiFp(this.configuration).apiV1RegistrationCreate(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResendEmailVerification} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1RegistrationResendEmailCreate(data: ResendEmailVerification, options?: any) {
        return ApiApiFp(this.configuration).apiV1RegistrationResendEmailCreate(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyEmail} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1RegistrationVerifyEmailCreate(data: VerifyEmail, options?: any) {
        return ApiApiFp(this.configuration).apiV1RegistrationVerifyEmailCreate(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve run by viewer token
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1RunsViewRead(token: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1RunsViewRead(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} runId 
     * @param {ViewerToken} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1RunsViewerTokensCreate(runId: string, data: ViewerToken, options?: any) {
        return ApiApiFp(this.configuration).apiV1RunsViewerTokensCreate(runId, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} runId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1RunsViewerTokensDelete(runId: string, id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1RunsViewerTokensDelete(runId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} runId 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1RunsViewerTokensList(runId: string, limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1RunsViewerTokensList(runId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1SesMessagesList(limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1SesMessagesList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1SubscriptionsDelete(options?: any) {
        return ApiApiFp(this.configuration).apiV1SubscriptionsDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1SubscriptionsRead(options?: any) {
        return ApiApiFp(this.configuration).apiV1SubscriptionsRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
     * @param {User} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1UserPartialUpdate(data: User, options?: any) {
        return ApiApiFp(this.configuration).apiV1UserPartialUpdate(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1UserRead(options?: any) {
        return ApiApiFp(this.configuration).apiV1UserRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @summary Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.
     * @param {User} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1UserUpdate(data: User, options?: any) {
        return ApiApiFp(this.configuration).apiV1UserUpdate(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1UsersDelete(id: string, options?: any) {
        return ApiApiFp(this.configuration).apiV1UsersDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1UsersList(limit?: number, offset?: number, options?: any) {
        return ApiApiFp(this.configuration).apiV1UsersList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UserManage} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1UsersPartialUpdate(id: string, data: UserManage, options?: any) {
        return ApiApiFp(this.configuration).apiV1UsersPartialUpdate(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UserManage} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiV1UsersUpdate(id: string, data: UserManage, options?: any) {
        return ApiApiFp(this.configuration).apiV1UsersUpdate(id, data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DownloadApi - axios parameter creator
 * @export
 */
export const DownloadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} token 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRead: async (token: string, filename: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('downloadRead', 'token', token)
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('downloadRead', 'filename', filename)
            const localVarPath = `/download/{token}/{filename}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)))
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DownloadApi - functional programming interface
 * @export
 */
export const DownloadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DownloadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} token 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadRead(token: string, filename: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadRead(token, filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DownloadApi - factory interface
 * @export
 */
export const DownloadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DownloadApiFp(configuration)
    return {
        /**
         * 
         * @param {string} token 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRead(token: string, filename: string, options?: any): AxiosPromise<void> {
            return localVarFp.downloadRead(token, filename, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DownloadApi - object-oriented interface
 * @export
 * @class DownloadApi
 * @extends {BaseAPI}
 */
export class DownloadApi extends BaseAPI {
    /**
     * 
     * @param {string} token 
     * @param {string} filename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadApi
     */
    public downloadRead(token: string, filename: string, options?: any) {
        return DownloadApiFp(this.configuration).downloadRead(token, filename, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Vonage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksInboundMessageCreate: async (data: Vonage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('webhooksInboundMessageCreate', 'data', data)
            const localVarPath = `/webhooks/inbound-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksInboundMessageList: async (limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/webhooks/inbound-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SESWebhook} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksSesCreate: async (data: SESWebhook, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('webhooksSesCreate', 'data', data)
            const localVarPath = `/webhooks/ses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MailgunTrackingWebhook} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksTrackingCreate: async (data: MailgunTrackingWebhook, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('webhooksTrackingCreate', 'data', data)
            const localVarPath = `/webhooks/tracking/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Vonage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhooksInboundMessageCreate(data: Vonage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Vonage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksInboundMessageCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhooksInboundMessageList(limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20022>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksInboundMessageList(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SESWebhook} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhooksSesCreate(data: SESWebhook, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SESWebhook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksSesCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MailgunTrackingWebhook} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhooksTrackingCreate(data: MailgunTrackingWebhook, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailgunTrackingWebhook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksTrackingCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhooksApiFp(configuration)
    return {
        /**
         * 
         * @param {Vonage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksInboundMessageCreate(data: Vonage, options?: any): AxiosPromise<Vonage> {
            return localVarFp.webhooksInboundMessageCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksInboundMessageList(limit?: number, offset?: number, options?: any): AxiosPromise<InlineResponse20022> {
            return localVarFp.webhooksInboundMessageList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SESWebhook} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksSesCreate(data: SESWebhook, options?: any): AxiosPromise<SESWebhook> {
            return localVarFp.webhooksSesCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MailgunTrackingWebhook} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksTrackingCreate(data: MailgunTrackingWebhook, options?: any): AxiosPromise<MailgunTrackingWebhook> {
            return localVarFp.webhooksTrackingCreate(data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
    /**
     * 
     * @param {Vonage} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public webhooksInboundMessageCreate(data: Vonage, options?: any) {
        return WebhooksApiFp(this.configuration).webhooksInboundMessageCreate(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public webhooksInboundMessageList(limit?: number, offset?: number, options?: any) {
        return WebhooksApiFp(this.configuration).webhooksInboundMessageList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SESWebhook} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public webhooksSesCreate(data: SESWebhook, options?: any) {
        return WebhooksApiFp(this.configuration).webhooksSesCreate(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MailgunTrackingWebhook} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public webhooksTrackingCreate(data: MailgunTrackingWebhook, options?: any) {
        return WebhooksApiFp(this.configuration).webhooksTrackingCreate(data, options).then((request) => request(this.axios, this.basePath));
    }
}


