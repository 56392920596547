import { AxiosResponse } from "axios";

const asyncPromise = (promise: Promise<AxiosResponse>): Promise<any> => {
  return new Promise((resolve, reject) => {
    promise
      .then(async (...response) => {
        resolve(response);
      })
      .catch(async (...error) => {
        reject(error);
      });
  });
};

export default asyncPromise;
