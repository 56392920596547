const Config = {
  API_ENDPOINT: "https://api.testthetest.com",
  SPRING_API_ENDPOINT: "https://service.testthetest.com",
  SPRING_API_KEY: "Vzs6axhkUy8kNyXQbmowUq5ztJRwyTeZ",
  STRIPE_PUBLIC_KEY:
    "pk_test_51L3Va1BhUPDOzUuNAtPZAnblOQxQGSAErr11dvp6aliA901ensEtYgKd7LNPIrZB3SXkBimMwIJqu5V3Qh4OePTC00ZPdHBxQp",
  SENTRY_INIT: true,
};

export default Config;
