import {
  QueryClient,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type { AxiosError } from "axios";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // https://tanstack.com/query/v4/docs/react/reference/useQuery
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export type QueryHookFn<TVariables, Data> = <
  TData = Data,
  TError = Error | AxiosError,
>(
  options: Pick<
    UseQueryOptions<Data, Error, TData>,
    "select" | "enabled" | "keepPreviousData" | "initialData"
  > & {
    variables: TVariables;
  },
) => UseQueryResult<TData, TError>;
