import { CheckStatusEnum } from "@api";

export enum Key {
  COOKIE_ACCESS_TOKEN = "KeyCookieAccessToken",
}

export const caseStatsStatusTitle = (status: CheckStatusEnum): string => {
  switch (status) {
    case CheckStatusEnum.P:
      return "PASSED";

    case CheckStatusEnum.F:
      return "FAILED";

    case CheckStatusEnum.S:
      return "SKIPPED";

    case CheckStatusEnum.H:
      return "NEEDS ATTENTION";

    default:
      return "-";
  }
};

export const checkStatusTitle = (status: CheckStatusEnum): string => {
  switch (status) {
    case CheckStatusEnum.P:
      return "PASSED";

    case CheckStatusEnum.F:
      return "FAILED";

    case CheckStatusEnum.S:
      return "SKIPPED";

    case CheckStatusEnum.H:
      return "NEEDS ATTENTION";

    default:
      return "-";
  }
};

export const checkStatusVariant = (
  status: CheckStatusEnum,
):
  | "primary"
  | "inherit"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning" => {
  switch (status) {
    case CheckStatusEnum.P:
      return "success";

    case CheckStatusEnum.F:
      return "error";

    case CheckStatusEnum.S:
      return "info";

    case CheckStatusEnum.H:
      return "warning";

    default:
      return "primary";
  }
};
