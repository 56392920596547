import { useContext } from "react";
import { ApiApi } from "@api";
import { ApiContext } from "@storage/ApiProvider";

const useApi = (): ApiApi => {
  const { api } = useContext(ApiContext);

  if (!api) {
    throw new Error("ApiProvider is required");
  }

  return api;
};

export default useApi;
