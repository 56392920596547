import { ThemeOptions, createTheme, Shadows } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";
import { Raleway } from "next/font/google";

const raleway = Raleway({
  weight: ["400", "500", "700", "900"],
  subsets: ["latin"],
  display: "swap",
});

const themeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 978,
      lg: 1140,
      xl: 1540,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#4048ff",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#F36945",
      contrastText: "#243050",
    },
    error: {
      main: "#ff1744",
    },
    info: {
      main: "#f3f7fa",
    },
    success: {
      main: "#2e7d32",
    },
  },
  shape: {
    borderRadius: 14,
  },
  shadows: ["none", ...Array(25).fill("none")] as Shadows,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 18,
        },
        sizeSmall: {
          fontSize: "0.9rem",
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "::placeholder": {
            color: "red",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: "none",
          display: "inline-block",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "0.9rem",
          borderRadius: "2rem",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: { fontSize: "0.75rem" },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "1px solid #bbdefb",
        },
        columnHeaders: {
          backgroundColor: "#e3f2fd",
          border: "none",
        },
        footerContainer: {
          borderBottomLeftRadius: "0.75rem",
          borderBottomRightRadius: "0.75rem",
          backgroundColor: "#e3f2fd",
        },
        columnHeaderTitle: {
          fontWeight: "900",
          color: "#555",
        },
        sortIcon: {
          color: "#e17a7e",
        },
        menuIconButton: {
          color: "#e17a7e",
        },
        panel: {
          "& .MuiPaper-rounded": {
            borderRadius: "1rem",
            boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.1)",
          },
        },
        menu: {
          "& .MuiPaper-rounded": {
            borderRadius: "1rem",
            boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.1)",
          },
        },
      },
    },
  },
  typography: {
    fontFamily: raleway.style.fontFamily,
    fontSize: 12,
    h1: {
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "1.35rem",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "1.25rem",
      fontWeight: "bold",
    },
    h4: {
      fontSize: "1.125rem",
      fontWeight: "bold",
    },
    subtitle1: {
      fontSize: "1.05rem",
      color: "#555",
    },
    subtitle2: {
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#777",
    },
  },
};

export const theme = createTheme(themeOptions);
